import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature2',
  templateUrl: './feature2.component.html',
  styleUrls: ['./feature2.component.css']
})
export class Feature2Component implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'Features',
    description: 'Faff about only a quid blower I don’t want no agro bleeding chimney pot burke tosser cras nice one boot fanny.!',
  };
  
  List: any[] = [
    { title: 'Delivery', hoverClass: 'blue-hover' ,icon: 'flaticon flaticon-growth', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'E-News', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-paper-plane', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Facility Booking', hoverClass: 'green-hover' ,icon: 'flaticon flaticon-professional', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Instant Notification', hoverClass: 'blue-hover' ,icon: 'flaticon flaticon-reward', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    // { title: 'Delivery', hoverClass: 'blue-hover' ,icon: 'flaticon flaticon-growth', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    // { title: 'E-News', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-paper-plane', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    // { title: 'Facility Booking', hoverClass: 'green-hover' ,icon: 'flaticon flaticon-professional', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    // { title: 'Instant Notification', hoverClass: 'blue-hover' ,icon: 'flaticon flaticon-reward', desc: 'There are many variations of passages of Lorem Ipsum available.'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
