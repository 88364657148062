
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-two',
  templateUrl: './slider-two.component.html',
  styleUrls: ['./slider-two.component.css']
})
export class SliderTwoComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: 'ANPR - Intelligence.',
    description: ' '
  };

  service1: any[] = [
    { title : 'High efficiency ANPR  for vehicle under 30kmph'},
    { title : 'High performance to detect vehicles from video ' },
    { title : 'Structural data: Vehicle size (11 sizes) Vehicle color (11 colors in daytime)'},
    // { title : 'LED Screen: 4 row & 8 characters, red and green dual color, 1024Dots (64*16), support rolling display'},
    // { title : 'Support to display info including LP number, pass sign, remark info. Support two display modes: idle & vehicle pass'},
    // { title : 'All in one package and ready for installation'},
  ];
  
  
  service2: any[] = [
    // { title : 'Social Sharing' },
    // { title : 'Retention' },
    // { title : 'Reviews Generation' }
  ];

  
  constructor() { }

  ngOnInit() {
  }

}

