<div id="loading">
    <div id="loading-center">
        <img src="./assets/images/vajra/icon.png" alt="loader" />
    </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" [logoWhite]="logoWhite" [logoClass]="'logo'"
    className="iq-gradient main-header" [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox6 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content">
    <div style="margin-top: 8rem;"></div>
    <app-how-work2></app-how-work2>
    <app-services></app-services>
    <!-- <app-feature2></app-feature2> -->
    <app-how-work></app-how-work>
    <!-- <app-business-need></app-business-need> -->
    <app-feature></app-feature>
    <!-- <app-testimonial></app-testimonial> -->
    <!-- <app-post></app-post> -->

    <!-- <p-carousel [value]="appScreenShots" [circular]="true" [orientation]="'horizontal'" [responsiveOptions]="responsiveOptions" [autoplayInterval]="1000" [numScroll]="1">
        <ng-template let-ss pTemplate="item">
            <img [src]="ss" style="height: 130px;" >
        </ng-template>
    </p-carousel> -->
</div>
<!-- End Main content -->
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle"
    [footerDescription]="footerDescription">
    <ul menu>
        <li><a class="text-white" href="/about-us">About Us</a></li>
        <li><a class="text-white" href="/contact-us">Contact Us</a></li>
        <li><a class="text-white" href="/privacy-policy">Privacy Policy</a></li>
        <li><a class="text-white" href="/terms-user">Terms Of Use</a></li>
        <li><a class="text-white" href="/end-user">User License</a></li>
    </ul>
    <ul about_us>
        <li>
            <a class="text-white" href="/community-app">Vajra Community App</a>
        </li>
        <li>
            <a class="text-white" href="/guard-patrol">Vajra Guard Patrol</a>
        </li>
        <li>
            <a class="text-white" href="/management-portal">Vajra Visitor Management</a>
        </li>
        <li>
            <a class="text-white" href="/access-control">Vajra Access Control</a>
        </li>
        <!-- <li><a class="text-white" href="javascript:void(0)">News</a></li> -->
    </ul>
    <div testimonial>
        <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
    </div>
</app-v-two-footer-style2>

<app-req-for-demo></app-req-for-demo>