import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.css"],
})
export class ServiceComponent implements OnInit {
  titleSectionProp: any = {
    title: "Automatic Number Plate Recognition",
    description:
      "Automatic Number Plate Recognition(ANPR) Also known as LPR (License Plate Recognition) or ALPR (Automatic License Plate Recognition).A highly accurate system capable of reading vehicle number plates using leading technology",
  };

  List: any[] = [
    {
      title: "2MP AI ANPR with Display",
      image: "./assets/sofbox-v2/images/home-1/cctv-camera.png",
      description:
        "",
    },
    {
      title: "ANPR - Intelligence",
      image: "./assets/sofbox-v2/images/home-1/robotic-brain.png",
      description:
        "",
    },
    {
      title: "ANPR with Barrier Gate",
      image: "./assets/sofbox-v2/images/home-1/barrier.png",
      description:
        "",
    },
    {
      title: "ANPR System Solution",
      image: "./assets/sofbox-v2/images/home-1/solution.png",
      description:
        "",
    },
  ];
  constructor() {}

  ngOnInit() {}
}
