import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/helpers/api.service';

@Component({
  selector: 'app-req-for-demo',
  templateUrl: './req-for-demo.component.html',
  styleUrls: ['./req-for-demo.component.css']
})
export class ReqForDemoComponent implements OnInit {
  @ViewChild('buttonCustom') buttonRef: ElementRef<HTMLElement>;
  form: FormGroup;
  successMessage:string =  "";
  loading: boolean = false;
  constructor(private apiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.buildForm();
  }
  

  buildForm(){
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      phone:['', Validators.required],
      organizationName:['', Validators.required],
      message:['', Validators.required]
    })
  }

  get f() { return this.form.controls; }

  save(){
    if(this.form.invalid) return alert("Error")
    this.loading = true
    const values = this.form.value
    this.apiService.reqForDemo(values).subscribe(()=>{
      this.loading = false
      this.successMessage = "Message sent successfully";
      this.form.reset();
      const gl = this
      setTimeout(()=>{
        this.successMessage = '';
        gl.buttonRef.nativeElement.click()
        console.log(gl.buttonRef)
      },5000 )
    })
  }
}
