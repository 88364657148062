import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bussines-need3',
  templateUrl: './bussines-need3.component.html',
  styleUrls: ['./bussines-need3.component.css']
})
export class BussinesNeed3Component implements OnInit {

  titleSectionProp: any = {
    title: 'Guard Patrol Management',
    description: 'Guard patrol management is a system used to manage security guards or personnel who are responsible for patrolling and monitoring a property or premises.',
    class: 'text-left'
  };

  List: any[] = [
    {
      title: 'Command Center',
      image: './assets/sofbox-v2/images/home-8/an.png',
      desc: 'This feature enables property managers to monitor various operations, such as security cameras, access control systems, and alarms, in real time.'
    },
    {
      title: 'GPS Fencing',
      image: './assets/sofbox-v2/images/home-8/tracking-app.png',
      desc: 'This feature enables property managers to track the location of people or objects within the virtual boundaries in real time.'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }
}
