import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { SofboxModule } from "../sofbox/sofbox.module";
import { IndexComponent } from "./index/index.component";
import { PlatformComponent } from "./components/platform/platform.component";
import { ServiceComponent } from "./components/service/service.component";
import { AboutComponent } from "./components/about/about.component";
import { SalesComponent } from "./components/sales/sales.component";
import { FeaturesComponent } from "./components/features/features.component";
import { PortfolioComponent } from "./components/portfolio/portfolio.component";
import { PostComponent } from "./components/post/post.component";
import { SliderModule } from "./components/slider/slider.module";
import { BusinessNeedComponent } from "./components/business-need/business-need.component";
import { BussinesNeed2Component } from './components/bussines-need2/bussines-need2.component';
import { BussinesNeed3Component } from './components/bussines-need3/bussines-need3.component';
import { Feature2Component } from './components/feature2/feature2.component';
import { Feature3Component } from './components/feature3/feature3.component';

const routes: Routes = [
  {
    path: "",
    component: IndexComponent,
  },
];

@NgModule({
  declarations: [
    IndexComponent,
    BusinessNeedComponent,
    PlatformComponent,
    ServiceComponent,
    AboutComponent,
    SalesComponent,
    FeaturesComponent,
    PortfolioComponent,
    PostComponent,
    BussinesNeed2Component,
    BussinesNeed3Component,
    Feature2Component,
    Feature3Component,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SliderModule,
    SofboxModule,
  ],
})
export class VajraManagementPortalModule {}
