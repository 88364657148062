import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SofboxModule } from '../sofbox/sofbox.module';
import { IndexComponent } from './index/index.component';
import { SliderModule } from './components/slider/slider.module';
import { FeaturesComponent } from './components/features/features.component';
import { TipsTrickComponent } from './components/tips-trick/tips-trick.component';
import { TipsTrick1Component } from './components/tips-trick1/tips-trick1.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PostComponent } from './components/post/post.component';
import { TipsTricks2Component } from './components/tips-tricks2/tips-tricks2.component';
import { TipsTricks3Component } from './components/tips-tricks3/tips-tricks3.component';
import { TipsTricks4Component } from './components/tips-tricks4/tips-tricks4.component';
import { Tabs1Component } from './components/tabs1/tabs1.component';
import { TipsTricks5Component } from './components/tips-tricks5/tips-tricks5.component';
import { Tabs2Component } from './components/tabs2/tabs2.component';
import { Features2Component } from './components/features2/features2.component';
import { FrequentlyaskedComponent } from './components/frequentlyasked/frequentlyasked.component';
import { MobileApplicationComponent } from './components/mobile-application/mobile-application.component';


const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
  }
];

@NgModule({
  declarations: [IndexComponent, FeaturesComponent, TipsTrickComponent, TipsTrick1Component, PortfolioComponent, PostComponent, TipsTricks2Component, TipsTricks3Component, TipsTricks4Component, Tabs1Component, TipsTricks5Component, Tabs2Component, Features2Component, FrequentlyaskedComponent, MobileApplicationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SliderModule,
    SofboxModule
  ]
})
export class VajraVisitorPanelModule { }
