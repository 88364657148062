import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features2',
  templateUrl: './features2.component.html',
  styleUrls: ['./features2.component.css']
})
export class Features2Component implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'Easy Guard Tours Via Mobile ',
    description: 'An ideal solution for security guard.',
  };
  
  List: any[] = [
    { title: 'Handy Day life Tool', hoverClass: 'blue-hover' ,icon: 'ion-ios-heart-outline', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'No Extra Equipment', hoverClass: 'org-hover' ,icon: 'ion-ios-color-wand-outline', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Send Event In Real Time', hoverClass: 'green-hover' ,icon: 'ion-ios-calendar-outline', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Prove Reliability', hoverClass: 'blue-hover' ,icon: 'ion-ios-checkmark-outline', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Smart Way of Patrolling', hoverClass: 'org-hover' ,icon: 'ion-ios-settings', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Quick And Easy Process', hoverClass: 'green-hover' ,icon: 'ion-ios-albums-outline', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Real Time SOS Alerts', hoverClass: 'blue-hover' ,icon: 'ion-ios-clock-outline', desc: 'There are many variations of passages of Lorem Ipsum available.'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
