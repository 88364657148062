import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {
  public logoWhite: string = "./assets/images/vajra/logo_white.png";
  public navLogo: string = "./assets/images/vajra/icon.png";
  public footerLogo: string = "./assets/images/vajra/logo_white.png";
  public footerText: string =
    "F-2-20,Sunsuria Forum No.1 Jalan Setia Dagang Al U13/Al Seksyen U13,40170 Shah Alam Selangor, Malaysia.";
  public footerTitle: string = "Ready to get started?";
  public footerDescription: string =
    "Manage your visitor & security on one platform.";

  public navItems: any = [
    {
      href: "/",
      title: "Home",
      _is_active: false,
      is_link: true,
    },
    {
      href: "#",
      title: "Solutions",
      is_link: false,
      _is_active: true,
      children: true,
      child: [
        { href: "/community-app", title: "Vajra Community App" },
        { href: "/guard-patrol", title: "Vajra Guard Patrol" },
        { href: "/visitor-panel", title: "Vajra Visitor Panel" },
        { href: "/management-portal", title: "Vajra Management Portal" },
        { href: "/access-control", title: "Vajra Access Control" },
      ],
    },
    { href: "/contact-us", title: "Contact Us", is_link: true },
  ];

  public List: any[] = [
    {
      name: "Suresh Rao",
      design: "CEO",
      image: "./assets/sofbox-v2/images/home-2/ceo.jpg",
      description:
        "We Work For Your IDEAS And Believe One Day Your IDEAS Work For You....",
    },
  ];

  constructor() {}
}
