import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Our Great Features',
    description: 'If you are planning on developing a product landing app or website, take a look at this beautiful-crafted'
  };

  List : any[] = [
    {
      title: 'Incidents Report',
      icon: 'ion-ios-lightbulb-outline',
      hoverClass: 'purple-hover',
      desc: 'Monitor incidents in real-time by gathering multimedia data (image, voice) with signature fields.'
    },
    {
      title: 'Guard Tour monitoring',
      icon: 'ion-ios-grid-view-outline',
      hoverClass: 'org-hover',
      desc: 'Monitor your guards and manage your workflow in an efficient way, automating all internal work process.'
    },
    {
      title: 'Guards schedules',
      icon: 'ion-ios-calendar-outline',
      hoverClass: 'green-hover',
      desc: 'Create guards’ schedule templates and follow specific scheduling during the whole patrol monitoring activities.'
    },
    {
      title: 'Timekeeping',
      icon: 'ion-ios-clock-outline',
      hoverClass: 'purple-hover',
      desc: 'Automatic record of check in and check out in real-time giving the ability to track working hours or days and control budget.'
    },
    {
      title: 'Advanced Reporting',
      icon: 'ion-ios-settings',
      hoverClass: 'purple-hover',
      desc: 'Create multiple reports(scanned, missed check points / incidents / patrol duration) using advanced filtering and export abilities (PDF, excel).'
    },
    {
      title: 'QR Code/NFC/Beacons',
      icon: 'ion-ios-paper-outline',
      hoverClass: 'purple-hover',
      desc: 'Scan QR Codes, NFC tags or Beacon checkpoints placed on buildings and territories and send immediate alerts and reports.'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
