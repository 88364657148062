import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// @ts-ignore
import logoImg from '../../../assets/sofbox-v2/images/logo-color.png';
// @ts-ignore
import footerLogo from '../../../assets/sofbox-v2/images/home-1/35.png';
// @ts-ignore
import contactLogo from '../../../assets/sofbox-v2/images/home-1/35.png';
import { PluginsService } from '../../sofbox/plugins.service';
import { ConstantsService } from 'src/app/helpers/constants.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class IndexComponent implements OnInit {

  public navLogo: string = './assets/images/vajra/icon.png';
  public logoWhite: string = './assets/images/vajra/logo_white.png';
  public footerLogo: string = './assets/images/vajra/logo_white.png';
  footerText: string = 'F-2-20,Sunsuria Forum No.1 Jalan Setia Dagang Al U13/Al Seksyen U13,40170 Shah Alam Selangor, Malaysia.';
  footerTitle: string = 'Ready to get started?';
  footerDescription: string = 'Manage your visitor & security on one platform.';
  public navItems: any = [
    {
      href: '#iq-home', title: 'Home',
      _is_active: true
    },
    { 
      href: '#', title: 'Solutions',
      is_link: false,
      _is_active: false,
      children: true,
      child: [
        { href: '/community-app', title: 'Vajra Community App' },
        { href: '/guard-patrol', title: 'Vajra Guard Patrol' },
        { href: '/visitor-panel', title: 'Vajra Visitor Panel' },
        { href: '/', title: 'Vajra Management Portal' },
        { href: '/access-control', title: 'Vajra Access Control' },
      ]
    },
    { href: '/contact-us', title: 'Contact Us', is_link: true  }
  ];

  List: any[] = [
    { name: 'Suresh Rao', design: 'CEO', image: './assets/sofbox-v2/images/home-2/ceo.jpg', description: 'We Work For Your IDEAS And Believe One Day Your IDEAS Work For You....'},
  ];

  titleSectionProp: any = {
    title: 'Get tips & tricks on how to skyrocket your sales.',
    description: ' '
  };

  testimonialSectionProp: any = {
    class: 'iq-rpt-zero'
  }
  
  testimonialList1: any[] = [
    { name: 'Alex xander', designation: 'CEO Sofbox', description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vero eos et consectetur accus adip adip.', image: './assets/sofbox-v2/images/home-1/25.jpeg'},
    { name: 'Julia Aann', designation: 'COO Sofbox', description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vero eos et consectetur accus adip adip.', image: './assets/sofbox-v2/images/home-1/26.jpeg'},
    { name: 'Mac Znder', designation: 'Manager Sofbox', description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vero eos et consectetur accus adip adip.', image: './assets/sofbox-v2/images/home-1/27.jpeg'}
  ];

  titleSectionProp2: any = {
    title: 'Contact with our team',
    description: 'If you are planning on developing a product landing'
  };

  contactSectionProp: any = {
    class: 'pt-0',
    id: 'iq-contact'
  }
  contactDetail: any = {
    logo: contactLogo,
    address: '9840 Shore St. Fond Du Lac, WI 54935',
    number: '0 1223 456 789',
    mail: 'mail@sofbox.com'
  };

  constructor(private plugins: PluginsService, private constantsService: ConstantsService) {
    this.logoWhite = this.constantsService.logoWhite;
    this.navLogo = this.constantsService.navLogo;
    this.footerLogo = this.constantsService.footerLogo;
    this.footerText = this.constantsService.footerText;
    this.footerTitle = this.constantsService.footerTitle;
    this.footerDescription = this.constantsService.footerDescription;

    this.navItems = this.constantsService.navItems;
    this.List = this.constantsService.List
   }

  ngOnInit() {
    // Init all plugins...
    const current = this;
    current.plugins.revolutionSlider();
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      current.plugins.index();
    }, 200);
  }

}
