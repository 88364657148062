import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Modules',
    description: 'If you are planning on developing a product landing app or website, take a look at this beautiful-crafted'
  };

  List: any[] = [
    { title: 'Vajra Patrol Management', image: './assets/sofbox-v2/images/home-1/01.png', description: 'There are many variations of passages of Lorem Ipsum available'},
    { title: 'Vajra Visitor Management', image: './assets/sofbox-v2/images/home-1/02.png', description: 'There are many variations of passages of Lorem Ipsum available'},
    { title: 'Vajra Resident Management', image: './assets/sofbox-v2/images/home-1/03.png', description: 'There are many variations of passages of Lorem Ipsum available'}
  ]

  constructor() { }

  ngOnInit() {
  }

}
