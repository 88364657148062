<section id="iq-about" class="pb-0">
    <div class="container">
        <div class="row align-item-center">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="row custom-tab verticaltab3 flex-row-reverse">
                <div class="col-lg-6 col-md-12">
                    <ul class="nav nav-pills nav-pills-style-3 mb-5" id="pills-tab" role="tablist"
                        aria-orientation="vertical">
                        <li *ngFor="let list of List; let i = index" class="nav-item">
                            <a class="nav-link active show" data-toggle="pill">
                                <div class="media">
                                    <a class="mb-2 active-title" data-toggle="collapse" href="#demo-{{i}}">
                                        {{list.title }}
                                    </a>
                                    <div class="media-body collapse" id="demo-{{i}}">
                                        <p class="mb-0">{{ list.description }}</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-12  show_content d-flex align-items-center">
                    <div class="tab-pane fade {{ 'active show' }} d-flex justify-content-center">
                        <img [src]="image" class="img-fluid" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>