<div class="media">
    <div class="{{class}}">
        <div class="circle-icon">
            <i aria-hidden="true" class="{{icon}}"></i>
        </div>
    </div>
    <div class="media-body">
        <h5 class="color-text mb-2">{{title}}</h5>
        <p>{{ description }}</p>
    </div>
</div>