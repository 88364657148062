
        <div class="row">
            <div class="col-lg-12">
                <div class="custom-tab horizontaltab  ">
                    <div class="show_content">
                        <div id="tab-getting-started" class="tab-pane fade active show">
                            <div class="iq-accordion career-style faq-style">
                                <div class="iq-accordion-block" style="visibility: hidden;">
                                    <div class="active-faq clearfix d-none">
                                        <div class="container">
                                            <div class="row">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-details">
                                    </div>
                                </div>
                                <div class="iq-accordion-block" *ngFor="let item of tabs;let i as index">
                                    <div class="active-faq clearfix">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-sm-12"><a class="accordion-title"><span> {{ item.title }}</span> </a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-details">
                                        <p class="mb-0">{{ item.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>