import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// @ts-ignore
import logoImg from '../../../assets/sofbox-v2/images/logo-color.png';
// @ts-ignore
import logoWhite from '../../../assets/sofbox-v2/images/logowhite.png';
// @ts-ignore
import footerLogo from '../../../assets/sofbox-v2/images/home-2/logo-white.png';
import { PluginsService } from '../../sofbox/plugins.service';
import { ConstantsService } from 'src/app/helpers/constants.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit {
  public logoWhite: string = './assets/images/vajra/logo_white.png';
  public navLogo: string = './assets/images/vajra/icon.png';
  public footerLogo: string = './assets/images/vajra/logo_white.png';
  footerText: string = 'F-2-20,Sunsuria Forum No.1 Jalan Setia Dagang Al U13/Al Seksyen U13,40170 Shah Alam Selangor, Malaysia.';
  footerTitle: string = 'Ready to get started?';
  footerDescription: string = 'Manage your visitor & security on one platform.';
  
  public navItems: any = [];

  appScreenShots = ["./assets/sofbox-v2/images/home-1/14.png","./assets/sofbox-v2/images/home-1/14.png","./assets/sofbox-v2/images/home-1/14.png","./assets/sofbox-v2/images/home-1/14.png","./assets/sofbox-v2/images/home-1/14.png", "./assets/sofbox-v2/images/home-1/14.png","./assets/sofbox-v2/images/home-1/14.png","./assets/sofbox-v2/images/home-1/14.png"]
  
  List: any[] = [
    { name: 'Suresh Rao', design: 'CEO', image: './assets/sofbox-v2/images/home-2/ceo.jpg', description: 'We Work For Your IDEAS And Believe One Day Your IDEAS Work For You....'},
  ];
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];
  constructor(private plugins: PluginsService, private constantsService: ConstantsService) { 
    this.logoWhite = this.constantsService.logoWhite;
    this.navLogo = this.constantsService.navLogo;
    this.footerLogo = this.constantsService.footerLogo;
    this.footerText = this.constantsService.footerText;
    this.footerTitle = this.constantsService.footerTitle;
    this.footerDescription = this.constantsService.footerDescription;

    this.navItems = this.constantsService.navItems;
    this.List = this.constantsService.List
    window.document.title = 'Vajra Community App';
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

  ngOnInit() {
    // Init all plugins...
    const current = this;
    current.plugins.revolutionSlider();
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      current.plugins.index();
    }, 200);
  }

}
