<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="col-lg-6">
                <div>
                    <h4>Start a Security Patrol Tour</h4>
                    <p>You can now start a new patrol by the simple press of a button. Guard tour accomplishment is now easier than ever via your smartphone. Just get on the place, launch Vajra-Patrol mobile app and Start a patrol tour. At the same moment, an alarm event is sent to the Vajra-Patrol web application via a cloud server, indicating that a new patrol has just launched..</p>
                </div>


            </div>
            <div class="col-lg-6">
                <div class="popup-images align-right">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/Start a Security Patrol Tour.jpg" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div class="popup-images align-right">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/Multimedia.jpg" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div>
                    <h4>Add Multimedia</h4>
                    <p>You can not only send events but also communicate with the managing staff via multimedia interaction, including: Text messages Images captured at the moment of patrolling Audio messages All of the messages include signature validation to claim legal processes and extinguish ambiguity for guard's activities..</p>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div>
                    <h4>Security Guard Incident Report</h4>
                    <p>When a guard comes up with an urgent situation, he can send an immediate SOS alert to the monitoring center pressing the red SOS button. In addittion, he can choose between a list of predefined incidents to send, such as: A broken front door Missing keys Open Lights Broken windows</p>
                </div>
            </div>
            
            <div class="col-lg-6">
                <div class="popup-images align-right">
                    <!-- <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/n4.png" alt="image1">
                    </div> -->
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/IncidentReport.jpg" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div class="popup-images align-right">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/Security.jpg" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div>
                    <h4>A Security Guard Checkpoint System</h4>
                    <p>It could never be more simple and real-time! Just pass from the buildings where checkpoints are placed (QRcode, Beacons or NFC tags), scan QR codes, pass the smartphone above NFC or scan Beacons from distance and a patrol event will be visible in the QR-Patrol web application in real-time. It simply means: Higher security level for both security guards and assets, as the managing staff is now aware of guard's position and can take immediate action in a case of alert No need for special security guard equipment (only a smartphone!) High reliability towards clients, as they can receive real-time email alerts when their buildings/assets are checked by security officers.</p>
                </div>
            </div>
        
        </div>
    </div>
</section>