<div class="request-for-demo">
  <button data-toggle="modal" data-target="#exampleModal">
    Request For Demo
  </button>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" style="backdrop-filter: blur(3px)">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content position-relative">
      <form [formGroup]="form" class="modal-body">
        <h3>Request For Demo</h3>
        <div class="row mt-3">
          <div class="col-lg-12">
            <input type="text" class="form-control pb-0 mb-2" id="inputName" formControlName="name"
              placeholder="Your Name" />
            <div *ngIf="f.name.touched && f.name.errors" class="text-danger text-small">
              <div>Name is required</div>
            </div>
          </div>
          <div class="col-lg-6">
            <input type="Email" class="form-control pb-0 mb-2 mt-3" id="inputEmail" formControlName="email"
              placeholder="Your Email" />
            <div *ngIf="f.email.touched && f.email.errors" class="text-danger text-small">
              <div>Email is required</div>
            </div>
          </div>
          <div class="col-lg-6">
            <input type="number" class="form-control pb-0 mb-2 mt-3" id="inputEmail" formControlName="phone"
              placeholder="Phone" />
            <div *ngIf="f.phone.touched && f.phone.errors" class="text-danger text-small">
              <div>Phone is required</div>
            </div>
          </div>

          <div class="col-lg-12">
            <input type="text" class="form-control pb-0 mb-2 mt-3" id="inputEmail" formControlName="organizationName"
              placeholder="Organization Name" />
            <div *ngIf="f.organizationName.touched && f.organizationName.errors" class="text-danger text-small">
              <div>Organization Name is required</div>
            </div>
          </div>
          <div class="col-lg-12">
            <textarea class="form-control pb-0 mb-2 mt-3" id="exampleFormControlTextarea1" rows="3"
              formControlName="message" placeholder="Your Message"></textarea>
            <div *ngIf="f.message.touched && f.message.errors" class="text-danger text-small">
              <div>Message is required</div>
            </div>
          </div>
        </div>
      </form>
      <div class="w-h-100" *ngIf="loading">
        <div class="loader"></div>
      </div>
      <div class="text-success text-center pb-4" *ngIf="!loading && successMessage.length > 0">
        {{ successMessage }}
      </div>
      <button #buttonCustom type="button" class="button btn-sm bt-black mr-3" data-dismiss="modal" style="
          background-color: red;
          visibility: hidden;
          width: 0px;
          height: 0px;
        "></button>
      <div class="d-flex justify-content-end pb-3 pr-3" *ngIf="!loading && successMessage.length === 0">
        <button type="button" class="button btn-sm bt-black mr-3" data-dismiss="modal" style="background-color: red">
          Close
        </button>
        <button type="button" [disabled]="form.errors" (click)="save()" class="button btn-sm">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>