<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="col-lg-6">
                <div class="content-box">
                    <h4>User Management</h4>
                    <p>Vajra Patrol web application allows total control on different security entries' levels: administrators, managers, security companies, security guards and clients. A Vajra Patrol manager can control all the parameters regarding the levels down in the hierarchy. Vajra Patrol web application lets you add and edit all relevant information regarding the various users roles and allows the total remote management of any incident, guard, shcedule, client related to the security guard company or organization. Vajra Patrol web application lets you add and edit all relevant information regarding the various users roles and allows the total remote management of any incident, guard, shcedule, client related to the security guard company or organization.</p>
                </div>


            </div>
            <div class="col-lg-6">
                <div class="popup-images align-right">
                    <div class="img-one wow zoomIn userManagement center" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/user.png" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div class="popup-images center d-none d-lg-block">
                    <div class="img-one wow zoomIn advancedReporting" data-wow-duration="0.6s">
                        <img src="./assets/images/AdvancedReporting.jpg" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div class="content-box">
                    <h4>Advanced Reporting</h4>
                    <p>There are four basic reporting options, enabling export to PDF or excel format for further processing: Finished Scheduled Route Report, which informs about all accomplished or missed patrols within a specific time interval. Clients Events Report, including all events for a specific client with full details. Incidents Report, including all events filtered by clients' sites or guards. Guard Daily Tour Duration Report, informing about exact time and duration of accomplished guard tours.</p>
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div class="content-box">
                    <h4>Manage and Assign Checkpoints</h4>
                    <p>The security account manager using Vajra-Patrol can easily assign checkpoints (QR-codes, NFC tags, Beacons) via the web interface of Vajra-Patrol. Each chekcpoint is assigned to a specific client site and location and so predefined tours can be scheduled for each security guard. Checkpoints assignment is the core of Vajra-Patrol guard tour monitoring software as it interconnects mobile and web application via checkpoints scan accomplished by the security guards.</p>
                </div>
            </div>
            
            <div class="col-lg-6">
                <div class="popup-images center">
                    <!-- <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/n4.png" alt="image1">
                    </div> -->
                    <div class="img-one wow zoomIn checkpoints" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/checkpoint.png" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
        
        </div>
    </div>
</section>