import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  titleSectionProp: any = {
    class: 'mb-0',
    title: 'Popular features in Vajra Visitor',
    description: 'Vajra Visitor is a visitor management system that offers various features to streamline visitor management processes.',
    descClass: 'mb-0'
  };
  
  List: any[] = [
    { title: 'Walkin Visitor', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/reception.png', style:'height:60px;width:60px;left: 0px;', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Invite Visitor', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/list.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Appointment', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-1/tutorial.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Self Registration', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/self.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
     { title: 'Visitor E-Pass', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/test.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Check In/Out', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-1/check-in-out.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Visitor In/Out Tracking', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/web-analysis.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Overstay', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/keep-distance.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'}
  ]

  constructor() { }

  ngOnInit() {
  }

}
