<div id="loading">
  <div id="loading-center">
    <img src="./assets/images/vajra/icon.png" alt="loader" />
  </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" [navItemList]="navItems">
</app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox2 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content pt-5">
  <!-- <app-platform></app-platform> -->
  <app-service></app-service>
  <!-- <app-about></app-about> -->
  <app-sales></app-sales>
  <app-slider-two></app-slider-two>
  <app-slider-three></app-slider-three>
  <app-slider-four></app-slider-four>
  <!-- <app-features></app-features> -->
  <!-- <app-portfolio></app-portfolio> -->

  <!-- <app-v-two-testimonial [sectionProp]="testimonialSectionProp">
        <div imageList1 class="bg-img-jump left">
            <div class="wow zoomIn" data-wow-delay="0.2s">
                <img class="img-one" src="./assets/sofbox-v2/images/home-1/21.jpeg" alt="image1">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.4s">
                <img class="img-two" src="./assets/sofbox-v2/images/home-1/22.jpeg" alt="image2">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.6s">
                <img class="img-three" src="./assets/sofbox-v2/images/home-1/23.jpeg" alt="image3">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.8s">
                <img class="img-four" src="./assets/sofbox-v2/images/home-1/24.jpeg" alt="image4">
            </div>
        </div>

        <div testimonialTitle>
            <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
        </div>

        <div testimonialList>
            <app-v-two-testimonial-carousel2 [testimonialList]="testimonialList1"></app-v-two-testimonial-carousel2>
        </div>

        <div imageList2 class="bg-img-jump right">
            <div class="wow zoomIn" data-wow-delay="0.2s">
                <img class="img-one" src="./assets/sofbox-v2/images/home-1/21.jpeg" alt="image1">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.4s">
                <img class="img-two" src="./assets/sofbox-v2/images/home-1/22.jpeg" alt="image2">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.6s">
                <img class="img-three" src="./assets/sofbox-v2/images/home-1/23.jpeg" alt="image3">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.8s">
                <img class="img-four" src="./assets/sofbox-v2/images/home-1/24.jpeg" alt="image4">
            </div>
        </div>

    </app-v-two-testimonial>

    <app-post></app-post>

    <app-v-two-contact-map [sectionProp]="contactSectionProp" [contactDetail]="contactDetail">
        <div titleSection>
            <app-v-two-section-title [titleSectionProp]="titleSectionProp2"></app-v-two-section-title>
        </div>
        <ul socailLink  class="share-social list-inline p-0">
            <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fab fa-github"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fab fa-instagram"></i></a></li>
        </ul>
        <form contactForm>
            <div class="row">
                <div class="col-lg-6">  <input type="text" class="form-control" id="inputName" placeholder="Your Name"> </div>
                <div class="col-lg-6">   <input type="Email" class="form-control" id="inputEmail" placeholder="Your Email"></div>
                <div class="col-lg-12">  <input type="text" class="form-control" id="inputSubject" placeholder="Your Subject"></div>
                <div class="col-lg-12"> <textarea class="form-control" id="exampleFormControlTextarea1" rows="7" placeholder="Your Message"></textarea>
                </div>
                <div class="col-lg-12">
                    <a class="button blue-btn" href="#">Send Message</a>
                </div>
            </div>
        </form>
    </app-v-two-contact-map> -->
</div>
<app-v-two-footer-style2
  [footerLogo]="footerLogo"
  [footerText]="footerText"
  [footerTitle]="footerTitle"
  [footerDescription]="footerDescription"
>
<ul menu>
  <li><a class="text-white" href="/about-us">About Us</a></li>
  <li><a class="text-white" href="/contact-us">Contact Us</a></li>
  <li><a class="text-white" href="/privacy-policy">Privacy Policy</a></li>
  <li><a class="text-white" href="/terms-user">Terms Of Use</a></li>
  <li><a class="text-white" href="/end-user">User License</a></li>
</ul>
<ul about_us>
  <li>
    <a class="text-white" href="/community-app">Vajra Community App</a>
  </li>
  <li>
    <a class="text-white" href="/guard-patrol">Vajra Guard Patrol</a>
  </li>
  <li>
    <a class="text-white" href="/management-portal">Vajra Visitor Management</a>
  </li>
  <li>
    <a class="text-white" href="/access-control">Vajra Access Control</a>
  </li>
  <!-- <li><a class="text-white" href="javascript:void(0)">News</a></li> -->
</ul>
  <div testimonial>
    <app-v-two-testimonial-carousel
      [testimonialList]="List"
    ></app-v-two-testimonial-carousel>
  </div>
</app-v-two-footer-style2>
<app-req-for-demo></app-req-for-demo>