<section class="pt-5">
    <div class="container">
        <div class="row full-img-left">
            
            <div class="col-12 col-lg-6  mb-5 mb-lg-0 d-lg-block d-none">
                <div class="d-flex flex-column align-items-center d-sm-block">
                    <img src="./assets/sofbox-v2/images/home-8/2.png" class="img-fluid" alt="blogimage1" style="visibility: hidden;">
                    <img src="./assets/sofbox-v2/images/home-8/2.png" class="img-fluid" alt="blogimage1">
                    <img src="./assets/sofbox-v2/images/home-8/2.png" class="img-fluid" alt="blogimage1" style="visibility: hidden;">
                </div>
               
            </div>
            <div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div class="row">
                    <div *ngFor="let list of List;let i = index" class="col-lg-4">
                        <div class="sox-services {{ list.hoverClass }}">
                            <div class="sox-icon">
                                <!-- <i aria-hidden="true" class="{{ list.icon }}"></i> -->
                                <img aria-hidden="true" [src]="list.icon" [style]="list.style">
                                <h6 class="text-hover mt-4">{{ list.title }}</h6>
                            </div>
                            <div class="service-number">
                                <span>{{ i + 1 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>