<section class="pt-0">
    <div class="container">
        <div class="row full-img-left">
            <!-- <div class="col-lg-6 mb-5 mb-lg-0 ">
                <img src="./assets/sofbox-v2/images/home-8/904.png" class="img-fluid" alt="blogimage1">
            </div> -->
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div class="row">
                    <div *ngFor="let list of List;let i = index" class="col-lg-2 col-6">
                        <div class="sox-services {{ list.hoverClass }}">
                            <div class="sox-icon mt-3 d-flex justify-content-center align-items-center flex-column">
                                <img [src]="list.icon" [style]="list.style" class="mt-3">
                                <h6 class="text-hover fs-13 mt-3">{{ list.title }}</h6>
                            </div>
                            <div class="service-number">
                                <span>{{ i + 1 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>