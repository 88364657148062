import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: '2MP AI ANPR with Display.',
    description: ' '
  };


  service1: any[] = [
    { title : 'All in one design'},
    { title : 'All cables hidden inside pole' },
    { title : 'All in one package and ready for installation'},
    { title : 'LED Screen: 4 row & 8 characters, red and green dual color, 1024Dots (64*16), support rolling display'},
    { title : 'Support to display info including LP number, pass sign, remark info. Support two display modes: idle & vehicle pass'},
    { title : 'All in one package and ready for installation'},
  ];
  
  
  service2: any[] = [
    // { title : 'Social Sharing' },
    // { title : 'Retention' },
    // { title : 'Reviews Generation' }
  ];

  
  constructor() { }

  ngOnInit() {
  }

}
