

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature3',
  templateUrl: './feature3.component.html',
  styleUrls: ['./feature3.component.css']
})
export class Feature3Component implements OnInit {

  titleSectionProp: any = {
    class: 'text-left mb-0',
    title: 'Popular features in Vajra Patrol Managment',
    description: 'Vajra Patrol Management is a security guard management system that enables property managers to track security guards movements in real-time using GPS technology.',
    descClass: 'mb-0'
  };
  
  
  List: any[] = [
    { title: 'Checkpoints', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/location.png', style:'height:60px;width:60px;left: 0px;', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'GPS Fencing', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/tracking-app.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Patrol', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-1/laptop.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Report Incident', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/performance.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
     { title: 'Schedule Configuartion', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/schedule.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'SOS Alert', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-1/sos_icon.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Live Tracking', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/tracking.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Offline Tracking', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/offline.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'}
  ]
  constructor() { }

  ngOnInit() {
  }

}
