import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-need',
  templateUrl: './business-need.component.html',
  styleUrls: ['./business-need.component.css']
})
export class BusinessNeedComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Vajra Visitor',
    description: 'A visitor panel refers to a feature in a property management or security system that allows authorized personnel to monitor and manage visitor access to a property.',
    class: 'text-left'
  };

  List: any[] = [
    {
      title: 'Visitor Database',
      image: './assets/sofbox-v2/images/home-8/dashboard.png',
      desc: 'This panel typically includes a searchable database that stores visitor information, including their name, contact information, date and time of visit, and reason for visiting.'
    },
    {
      title: 'Visitor Instant Notification',
      image: './assets/sofbox-v2/images/home-8/notification.png',
      desc: 'Automated push notifications or emails are sent to authorized personnel or residents when a visitor checks in or out of the property.'
    },
    // {
    //   title: 'Buyer Protection',
    //   image: './assets/sofbox-v2/images/home-8/906.png',
    //   desc: 'There are many variations of passages of Lorem Ipsum available.'
    // }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
