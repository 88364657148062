import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-three',
  templateUrl: './slider-three.component.html',
  styleUrls: ['./slider-three.component.css']
})
export class SliderThreeComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: 'ANPR with Barrier Gate.',
    description: ' '
  };

  service1: any[] = [
    { title : 'Traffic Direction'},
    { title : 'ANPR Cam' },
    { title : 'Barrier Gate'},
    { title : 'Entry loop coil'},
    { title : 'Closing loop coil'},
    // { title : 'All in one package and ready for installation'},
  ];
  
  
  service2: any[] = [
    // { title : 'Social Sharing' },
    // { title : 'Retention' },
    // { title : 'Reviews Generation' }
  ];

  
  constructor() { }

  ngOnInit() {
  }

}

