<section id="iq-feature" class="pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
        </div>
        <div class="row position-relative">
            <div class="layer_wrap iq-objects style-two right-top">
                <span class="iq-objects-02">
                    <img src="./assets/sofbox-v2/images/home-1/14.png" alt="drive02">
                </span>
            </div>
            <div class="row">
                <div *ngFor="let list of List" class="col-lg-3 col-6">
                    <div class="sox-services service-pattern wow fadeInUp text-left {{ list.hoverClass }}" data-wow-delay="0.4s">
                        <div class="sox-icon">
                            <h5 class="text-hover">{{ list.title }}</h5>
                            <i aria-hidden="true" class="{{ list.icon }}"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>