import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bussines-need2',
  templateUrl: './bussines-need2.component.html',
  styleUrls: ['./bussines-need2.component.css']
})
export class BussinesNeed2Component implements OnInit {

  titleSectionProp: any = {
    title: 'Resident Management',
    description: 'Managing resident information and communicating with residents. The portal allows property managers to streamline their workflows, automate routine tasks, and keep track of important resident information.',
    class: 'text-left'
  };

  List: any[] = [
    {
      title: 'Analytical Dashbaord',
      image: './assets/sofbox-v2/images/home-8/dashboard.png',
      desc: 'Provides a visual representation of data and key performance indicators.'
    },
    {
      title: 'Resident Notification',
      image: './assets/sofbox-v2/images/home-8/notification.png',
      desc: 'sending/receiving automated push notifications or emails to residents regarding important property-related information.'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }
}
