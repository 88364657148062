import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-tricks3',
  templateUrl: './tips-tricks3.component.html',
  styleUrls: ['./tips-tricks3.component.css']
})
export class TipsTricks3Component implements OnInit {
  titleSectionProp: any = {
    class: '',
    title: 'Instant Data availability - Centralized monitoring',
    descClass: "text-justify",
    description: ''
  };
  
  Services: any[] = [
    { title : 'User-friendly interface – minimal training required.' },
    { title : 'Neither hardware infrastructure required nor software installation - minimum cost requirements.' },
    { title : 'Complete visibility into scanned or missed checkpoints in real-time.' },
    { title : 'Weather-proof, low-cost, and high resistance NFC tags and QR-codes tags.' },
    { title : 'Instant activities reports and history logs.' },
  ];


//   
//  .
//  .
//  .
//  .
  constructor() { }

  ngOnInit(): void {
  }

}
