import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-tricks2',
  templateUrl: './tips-tricks2.component.html',
  styleUrls: ['./tips-tricks2.component.css']
})
export class TipsTricks2Component implements OnInit {
  titleSectionProp: any = {
    class: 'text-left',
    title: 'Increase Officers productivity and potential',
    descClass: "text-justify",
    description: ``
  };
  
  Services: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
