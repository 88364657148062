<div id="loading">
  <div id="loading-center">
    <img src="./assets/images/vajra/icon.png" alt="loader" />
  </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" className="main-header iq-gradient"
  [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox6 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<div class="main-content">
  <app-service [titleSection]="titleSectionProp" [list]="List2"></app-service>

  <app-service-tab [title]="vcaTitle" [image]="'./assets/images/home/vca.png'" [list]="vcaList"></app-service-tab>
  <!-- <app-v-two-section-title
    [titleSectionProp]="guardPatrolTop"
    ></app-v-two-section-title> -->

  <section class="popup-images align-left pb-0" id="iq-feature">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <app-v-two-section-title [titleSectionProp]="guardPatrolTop"></app-v-two-section-title>
          <div class="row">
            <div class="col-lg-6">
              <ul class="iq-mt-40 iq-list">
                <li *ngFor="let item of vgpFeatures" class="iq-tw-6 iq-mb-15">
                  <i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">{{
                    item.text }}</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <img src="./assets/sofbox-v2/images/home-7/Start a Security Patrol Tour.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-service-tab [title]="vvpTitle" [image]="'./assets/sofbox-v2/images/home-7/checkpoint.png'"
    [list]="vvpList"></app-service-tab>

  <!-- <app-feature></app-feature> -->
  <app-case-study></app-case-study>

  <section class="popup-images align-left pb-0 mb-5" id="iq-feature">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-2">
          <app-v-two-section-title [titleSectionProp]="anprPanelTop"></app-v-two-section-title>
          <div class="row">
            <div class="col-lg-6">
              <ul class="iq-mt-20 iq-list">
                <li *ngFor="let item of anprDetails" class="iq-tw-6 iq-mb-15">
                  <i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">{{
                    item.text }}</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <img src="./assets/images/anpr/2.png" alt="image1" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <app-pricing></app-pricing>
  <app-post></app-post> -->
</div>
<!-- End Main content -->
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle"
  [footerDescription]="footerDescription">
  <ul menu>
    <li><a class="text-white" href="/about-us">About Us</a></li>
    <li><a class="text-white" href="/contact-us">Contact Us</a></li>
    <li><a class="text-white" href="/privacy-policy">Privacy Policy</a></li>
    <li><a class="text-white" href="/terms-user">Terms Of Use</a></li>
    <li><a class="text-white" href="/end-user">User License</a></li>
  </ul>
  <ul about_us>
    <li>
      <a class="text-white" href="/community-app">Vajra Community App</a>
    </li>
    <li>
      <a class="text-white" href="/guard-patrol">Vajra Guard Patrol</a>
    </li>
    <li>
      <a class="text-white" href="/management-portal">Vajra Visitor Management</a>
    </li>
    <li>
      <a class="text-white" href="/access-control">Vajra Access Control</a>
    </li>
    <!-- <li><a class="text-white" href="javascript:void(0)">News</a></li> -->
  </ul>
  <div testimonial>
    <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
  </div>
</app-v-two-footer-style2>

<app-req-for-demo></app-req-for-demo>