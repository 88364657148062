<div id="loading">
  <div id="loading-center">
    <img src="./assets/images/vajra/icon.png" alt="loader" />
  </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" className="main-header" [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox6 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content">
    <div class="d-flex justify-content-center align-items-center d-sm-none">
        <img src="./assets/sofbox-v2/revslider/assets/index-7/n1.png" alt="img" width="907" height="623" data-no-retina>
    </div>

    <app-features></app-features>
    <app-tips-trick></app-tips-trick>
    <app-tips-tricks2></app-tips-tricks2>
    <app-tips-tricks3></app-tips-tricks3>
    <app-tips-tricks4></app-tips-tricks4>

    <app-tips-tricks5 [tabs]="webPortalTabs" [titleSectionProps]="webPortalTitle"></app-tips-tricks5>
    <app-mobile-application></app-mobile-application>
    <!-- <app-tabs2 [titleSectionProp]="mobileApplicationHeader" [tabs1]="mobileApplicationTabs1" [tabs2]="mobileApplicationTabs2"></app-tabs2> -->

    <app-features2></app-features2>
    <app-frequentlyasked></app-frequentlyasked>
    <div style="margin-top: 50px;"></div>
</div>
<!-- End Main content -->
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle" [footerDescription]="footerDescription">
    <ul menu>
        <li><a class="text-white" href="/about-us">About Us</a></li>
        <li><a class="text-white" href="/contact-us">Contact Us</a></li>
        <li><a class="text-white" href="/privacy-policy">Privacy Policy</a></li>
        <li><a class="text-white" href="/terms-user">Terms Of Use</a></li>
        <li><a class="text-white" href="/end-user">User License</a></li>
      </ul>
      <ul about_us>
        <li>
          <a class="text-white" href="/community-app">Vajra Community App</a>
        </li>
        <li>
          <a class="text-white" href="/guard-patrol">Vajra Guard Patrol</a>
        </li>
        <li>
          <a class="text-white" href="/management-portal">Vajra Visitor Management</a>
        </li>
        <li>
          <a class="text-white" href="/access-control">Vajra Access Control</a>
        </li>
        <!-- <li><a class="text-white" href="javascript:void(0)">News</a></li> -->
      </ul>
    <div testimonial>
        <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
    </div>
</app-v-two-footer-style2>
<app-req-for-demo></app-req-for-demo>