<section class="pt-0 sofbox-fullrside-img">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popup-images align-right d-lg-block d-none ">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-1/S.png" alt="image1">
                    </div>
                    <div class="d-block">
                        <div class="position-absolute img-two wow zoomIn" data-wow-delay="0.4s" data-wow-duration="0.8s" >
                            <!-- <img src="./assets/sofbox-v2/images/home-1/s.png" alt="image2"> -->
                        </div>
                        <!-- <div class="position-absolute img-three wow zoomIn" data-wow-delay="0.8s" data-wow-duration="1s">
                            <img src="./assets/sofbox-v2/images/home-1/11.png" alt="image3">
                        </div> -->
                        <!-- <div class="position-absolute img-four wow zoomIn" data-wow-delay="0.8s" data-wow-duration="1s" >
                            <img src="./assets/sofbox-v2/images/home-1/12.png" alt="image4">
                        </div> -->
                    </div>
                </div>
                <div class="layer_wrap iq-objects  style-two left-top" id="object_145818093">
                    <span class="iq-objects-02">
                        <img src="./assets/sofbox-v2/images/home-1/13.png" alt="drive02">
                    </span>
                    <!-- <span class="iq-objects-03">
                        <img src="./assets/sofbox-v2/images/home-1/08.png" alt="drive02">
                    </span> -->
                </div>
            </div>
            <div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="text-left">
                            <ul class="iq-list wow fadeInUp">
                                <li *ngFor="let service of service1"><i class="fa fa-check"></i><span>{{ service.title }} </span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="text-left">
                            <ul class="iq-list wow fadeInUp">
                                <li *ngFor="let service of service2"><i class="fa fa-check"></i><span>{{ service.title }} </span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>


