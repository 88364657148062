<section class="pb-5 position-relative" id="iq-feature">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="col-lg-3">
                <!-- <div class="button mt-5 mt-lg-0 mt-md-0">Explore More Feature</div> -->
            </div>
        </div>
        <div class="layer_wrap iq-objects style-two left-top">
            <span class="iq-objects-02">
				<img src="./assets/sofbox-v2/images/home-1/14.png" alt="drive02">
			</span>
        </div>
        <div class="row mt-5">
            <div *ngFor="let list of List" class="col-lg-3 col-md-4 col-6">
                <div class="sox-services service-pattern wow fadeInUp text-left {{ list.hoverClass }}" data-wow-delay="0.4s">
                    <div class="sox-icon">
                        <h5 class="text-hover">{{ list.title }}</h5>
                        <!-- <i aria-hidden="true" class="{{ list.icon }}"></i> -->
                        <img [src]="list.icon" [style]="list.style">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>