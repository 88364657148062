import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs2',
  templateUrl: './tabs2.component.html',
  styleUrls: ['./tabs2.component.css']
})
export class Tabs2Component implements OnInit {

  @Input('tabs1') tabs1: any[] = []
  @Input('tabs2') tabs2: any[] = []

  @Input('titleSectionProp') titleSectionProp:any = {
    
  }
  
  constructor() { }

  ngOnInit(): void {
  }

  tabActive(id) {
    // @ts-ignore
    const jquery = window.$;
    jquery('.show_content').find('.active').removeClass('active show');
    jquery('#' + id).addClass('active show');
  }

}
