import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs1',
  templateUrl: './tabs1.component.html',
  styleUrls: ['./tabs1.component.css']
})
export class Tabs1Component implements OnInit {

  @Input('tabs') tabs: any[] = []
  
  constructor() { }

  ngOnInit(): void {
  }

  tabActive(id) {
    // @ts-ignore
    const jquery = window.$;
    jquery('.show_content').find('.active').removeClass('active show');
    jquery('#' + id).addClass('active show');
  }
}
