import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'Visitor Features',
    description: 'Manage visitors with community app.',
  };
  
  List: any[] = [
    { title: 'Single Entry E-Pass', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-8/test.png',style:'height:60px;width:60px;left: 0px; padding-bottom:5px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Multiple Entry E-Pass', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-8/e-ticket (1).png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
    { title: 'Overstay', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-8/keep-distance.png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
    { title: 'Verify Visitor', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-8/man.png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
    { title: 'Upcoming Visitor', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-8/visitor.png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
    { title: 'Invitation', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-8/add-friend.png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
    { title: 'Visitor Notification', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-8/notification.png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
    { title: 'Appointments Approval', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-8/immigration.png', desc: 'There are many variations of passages of Lorem Ipsum available.',style:'height:60px;width:60px;left: 0px; padding-bottom:5px'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
