<section>
    <div class="container">
        
        <div class="row">
            <div class="col-12 align-items-center">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="col-lg-6">
                <div class="popup-images align-right">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <br />
                        <img src="./assets/sofbox-v2/images/home-7/3.png" alt="image1" style="transform: scale(1.1);">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="text-justify">
                    <p>Vajra-Patrol guard tour system relieves officers from daily time-consuming processes such as filling paper reports and making repetitive phone calls to the Monitoring Center, provoking confusion and misunderstandings. The whole procedure is defined by strict guidelines and simple actions to take. Guards and officers can quickly send incidents reports, implement patrols and any other guard service by taking advantage of the latest innovations in technology.
                    </p>
                </div>
                <div class="text-justify"> 
                    <p>Companies and Clients are also informed and engaged in the monitoring process in real-time, keeping remote control on every aspect of guard tour services and reacting immediately to any incidents as required. Every guard tour is verified in real-time and any promised service is met or unaccomplished.</p>
                </div>
            </div>
        </div>
    </div>
</section>