import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-work2',
  templateUrl: './how-work2.component.html',
  styleUrls: ['./how-work2.component.css']
})
export class HowWork2Component implements OnInit {
  titleSectionProp = {
    title: 'Management Office Features',
    description: 'Multiple features in one single app.',
    class: 'text-left'
  };

  List : any[] = [
    { title: 'Visitor Invitation', icon: './assets/sofbox-v2/images/home-8/list.png', hoverClass: 'purple-hover' , style:'height:60px;width:60px;left: 0px;'},
    { title: 'Appointment Approval', icon: './assets/sofbox-v2/images/home-8/tutorial.png', hoverClass: 'green-hover', style:'height:60px;width:60px;left: 0px;' },
    { title: 'E-Pass', icon: './assets/sofbox-v2/images/home-8/test.png', hoverClass: 'org-hover', style:'height:60px;width:60px;left: 0px;' },
    { title: 'Check-in/out', icon: './assets/sofbox-v2/images/home-8/check-in-out.png', hoverClass: 'purple-hover', style:'height:60px;width:60px;left: 0px;'},
    { title: 'Property Management', icon: './assets/sofbox-v2/images/home-8/propertymanagemnt.png', hoverClass: 'org-hover',style:'height:60px;width:60px; left: 0px;' },
    { title: 'Vehicle Management', icon: './assets/sofbox-v2/images/home-8/vehicle.jpg', hoverClass: 'green-hover', style:'height:60px;width:60px;left: 0px;' },
    { title: 'Parking Management', icon: './assets/sofbox-v2/images/home-8/parking-lot.png', hoverClass: 'purple-hover', style:'height:60px;width:60px;left: 0px;' },
    { title: 'Real Time Alert', icon: './assets/sofbox-v2/images/home-8/notification.png', hoverClass: 'green-hover', style:'height:60px;width:60px;left: 0px;' },
    { title: 'Incident and SOS', icon: './assets/sofbox-v2/images/home-8/sos_icon.png', hoverClass: 'purple-hover' , style:'height:60px;width:60px;left: 0px;'},
    { title: 'Real Time Vehicle Status', icon: './assets/sofbox-v2/images/home-8/parking-area.png', hoverClass: 'green-hover' , style:'height:60px;width:60px;left: 0px;'},
    { title: 'Central Dashboard', icon: './assets/sofbox-v2/images/home-8/dashboard.png', hoverClass: 'org-hover' , style:'height:60px;width:60px;left: 0px;'},
    { title: 'Tenant Management', icon: './assets/sofbox-v2/images/home-8/tenant.png', hoverClass: 'green-hover', style:'height:60px;width:60px;left: 0px;' },
    { title: 'Staff Management', icon: './assets/sofbox-v2/images/home-8/receptionist.png', hoverClass: 'org-hover' , style:'height:60px;width:60px;left: 0px;'},
    { title: 'Facility Booking', icon: './assets/sofbox-v2/images/home-8/facility.png', hoverClass: 'purple-hover' , style:'height:60px;width:60px;left: 0px;'},
    { title: 'E-News', icon: './assets/sofbox-v2/images/home-8/e-news.jpg', hoverClass: 'green-hover' , style:'height:60px;width:60px;left: 0px; border-radius: 100px;'},
    { title: 'Family Management', icon: './assets/sofbox-v2/images/home-8/family.jpg', hoverClass: 'org-hover' , style:'height:60px;width:60px;left: 0px;'},
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
