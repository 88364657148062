import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-four',
  templateUrl: './slider-four.component.html',
  styleUrls: ['./slider-four.component.css']

})
export class SliderFourComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: 'ANPR System Solution.',
    description: ' '
  };

  service1: any[] = [
    { title : 'NVR (Recording)'},
    { title : 'Network Switch' },
    { title : 'ANPR Cam with Display'},
    // { title : 'LED Screen: 4 row & 8 characters, red and green dual color, 1024Dots (64*16), support rolling display'},
    // { title : 'Support to display info including LP number, pass sign, remark info. Support two display modes: idle & vehicle pass'},
    // { title : 'All in one package and ready for installation'},
  ];
  
  
  service2: any[] = [
    // { title : 'Social Sharing' },
    // { title : 'Retention' },
    // { title : 'Reviews Generation' }
  ];

  
  constructor() { }

  ngOnInit() {
  }

}

