import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.css']
})
export class CaseStudyComponent implements OnInit {

  titleSectionProp: any = {
    title: 'Management Portal',
    description: 'Live Tracking Activity with the command center with all resident and visitor databases.'
  };

  List: any[] = [
    { title: 'Analytical dashboard', description: '', image: './assets/images/adminPanel/analyticalDashboard.png'},
    { title: 'Patrol Management Access', description: '', image: './assets/images/adminPanel/patrolManagement.png'},
    { title: 'Visitor management Access', description: '', image: './assets/images/adminPanel/visitorManagement.png'},
    { title: 'Resident Management Access', description: '', image: './assets/images/adminPanel/residentManagement.png'},
    { title: 'Vehicle Management Access', description: '', image: './assets/images/adminPanel/vehicleDatabase.png'},
    { title: 'Checkpoints Tracking', description: '', image: './assets/images/webPortal/4.png'},
    { title: 'Instant Alerts', description: '', image: './assets/images/webPortal/3.png'},
    { title: 'Assignment And Site', description: '', image: './assets/images/webPortal/1.png'},
    { title: 'User Management', description: '', image: './assets/images/webPortal/2.png'},
  ];

//   Analytical dashboard
// Patrol Management Access
// Visitor management Access
// Resident Management Access
// Vehicle Management Access
// Checkpoints and Patrol Tracking
// Instant Alerts 
// Assignment And Site
// User Management 

  constructor() { }

  ngOnInit(): void {
  }

}
