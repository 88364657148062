
<section  id="iq-feature" class="pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
        </div>
        <div class="row position-relative">
            <div class="layer_wrap iq-objects style-two right-top col-12 col-sm-6 col-lg-6">
                <span class="iq-objects-02">
                    <img src="./assets/sofbox-v2/images/home-1/14.png" alt="drive02">
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-6">
                <div class="row">
                  <app-tabs1 [tabs]="tabs"></app-tabs1>
                </div>
            </div>
        </div>
    </div>
</section>