import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-application',
  templateUrl: './mobile-application.component.html',
  styleUrls: ['./mobile-application.component.css']
})
export class MobileApplicationComponent implements OnInit {
   titleSectionProp?: any = {
    class: 'text-center',
    title: 'Mobile Application',
    description: 'Add value to your security guard services Simplify guard tour inspections via cloud and smartphone technology'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
