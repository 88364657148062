<p class="rs-p-wp-fix"></p>
<rs-module-wrap
  id="rev_slider_8_1_wrapper"
  data-alias="sofbox-8"
  data-source="gallery"
  style="
    background: transparent;
    padding: 0;
    margin: 0px auto;
    margin-top: 0;
    margin-bottom: 0;
  "
>
  <rs-module id="rev_slider_8_1" style="display: none" data-version="6.1.3">
    <rs-slides>
      <rs-slide
        data-key="rs-4"
        id="iq-home"
        data-title="Slide"
        data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;"
      >
        <img
          src="./assets/sofbox-v2/revslider/assets/index-8/transparent.png"
          data-bg="p:right center;"
          class="rev-slidebg"
          data-no-retina
        />

        <rs-layer
          id="slider-4-slide-4-layer-2"
          data-type="image"
          data-rsp_ch="on"
          data-xy="x:l,l,c,c;xo:804px,804px,33px,26px;y:t,t,m,m;yo:286px,186px,201px,162px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:669px,869px,437px,231px;h:410px,610px,307px,162px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2060;sp:1000;sR:2060;"
          data-frame_999="o:0;st:w;sR:5940;"
          style="z-index: 11; font-family: Roboto"
          ><img
            src="./assets/images/vajra/communityapp.png"
            alt="images"
            width="829"
            height="582"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-3-slide-3-layer-14"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:664px,664px,127px,78px;yo:103px,103px,312px,232px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:15px,15px,12px,10px;h:15px,15px,12px,10px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2400;sp:1000;sR:2400;"
          data-frame_999="o:0;st:w;sR:5600;"
          data-loop_0="y:20px;"
          data-loop_999="y:-20px;sp:3000;e:Sine.easeInOut;yym:t;"
          style="z-index: 17; font-family: Roboto"
          ><img
            src="./assets/sofbox-v2/revslider/assets/index-7/17-1.png"
            alt="img"
            width="15"
            height="15"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-3-slide-3-layer-14"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:864px,664px,127px,78px;yo:173px,103px,312px,232px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:15px,15px,12px,10px;h:15px,15px,12px,10px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2400;sp:1000;sR:2400;"
          data-frame_999="o:0;st:w;sR:5600;"
          data-loop_0="x:30;y:90px;"
          data-loop_999="x:70;y:-90px;sp:9000;e:Sine.easeInOut;yym:t;"
          style="z-index: 17; font-family: Roboto"
          ><img
            src="./assets/sofbox-v2/revslider/assets/index-7/17-1.png"
            alt="img"
            width="15"
            height="15"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-3-slide-3-layer-16"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:842px,842px,408px,251px;yo:654px,654px,317px,195px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:11px,11px,5px,3px;h:11px,11px,5px,3px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:1950;sp:1000;sR:1950;"
          data-frame_999="o:0;st:w;sR:6050;"
          data-loop_0="xR:100px;yR:-10px;"
          data-loop_999="xR:1000px;yR:-10px;crd:t;sp:35000;"
          style="z-index: 20; font-family: Roboto"
          ><img
            src="./assets/sofbox-v2/revslider/assets/index-7/10-2.png"
            alt="img"
            width="11"
            height="11"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-3-slide-3-layer-16"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:742px,842px,408px,251px;yo:454px,654px,317px,195px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:11px,11px,5px,3px;h:11px,11px,5px,3px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:1950;sp:1000;sR:1950;"
          data-frame_999="o:0;st:w;sR:6050;"
          data-loop_0="xR:10px;yR:-10px;"
          data-loop_999="xR:10px;yR:-80px;crd:t;sp:8000;"
          style="z-index: 20; font-family: Roboto"
          ><img
            src="./assets/sofbox-v2/revslider/assets/index-7/10-2.png"
            alt="img"
            width="11"
            height="11"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-3-slide-3-layer-16"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:242px,842px,408px,251px;yo:654px,654px,317px,195px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:11px,11px,5px,3px;h:11px,11px,5px,3px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:1950;sp:1000;sR:1950;"
          data-frame_999="o:0;st:w;sR:6050;"
          data-loop_0="xR:10px;yR:-10px;"
          data-loop_999="xR:10px;yR:-10px;crd:t;sp:3000;"
          style="z-index: 20; font-family: Roboto"
          ><img
            src="./assets/sofbox-v2/revslider/assets/index-7/10-2.png"
            alt="img"
            width="11"
            height="11"
            data-no-retina
          />
        </rs-layer>

        <rs-layer
          id="slider-3-slide-3-layer-15"
          data-type="image"
          data-rsp_ch="on"
          data-xy="x:l,l,r,r;xo:500px,500px,40px,24px;yo:449px,449px,218px,134px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:12px,12px,10px,6px;h:12px,12px,10px,6px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2230;sp:1000;sR:2230;"
          data-frame_999="o:0;st:w;sR:5770;"
          data-loop_0="o:0;"
          data-loop_999="sp:1500;e:Sine.easeInOut;yyf:t;"
          style="z-index: 18; font-family: Roboto"
          ><img
            src="./assets/sofbox-v2/revslider/assets/index-7/16-1.png"
            alt="img"
            width="20"
            height="20"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-4-slide-4-layer-8"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:537px,537px,340px,209px;yo:-23px,-23px,-99px,-61px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:1071px,1071px,520px,320px;h:864px,864px,419px,258px;"
          data-frame_0="x:50,50,24,14;"
          data-frame_1="sp:1000;"
          data-frame_999="o:0;st:w;"
          style="z-index: 8; font-family: Roboto"
        >
          <img
            src="./assets/sofbox-v2/revslider/assets/index-7/bg.png"
            alt="images"
            width="1071"
            height="864"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-4-slide-4-layer-11"
          class="now-font"
          data-type="text"
          data-color="#1e1e1e"
          data-rsp_ch="on"
          data-xy="x:l,l,c,c;xo:139px,139px,-6px,-3px;y:m;yo:-30px,-30px,-199px,-122px;"
          data-text="w:normal;s:44,44,30,24;l:70,70,44,34;a:left,left,center,center;"
          data-frame_0="y:100%;"
          data-frame_0_mask="u:t;"
          data-frame_1="st:750;sp:1200;sR:750;"
          data-frame_1_mask="u:t;"
          data-frame_999="o:0;st:w;sR:7050;"
          style="z-index: 13; font-family: Poppins"
          >Vajra Community App
        </rs-layer>
        <rs-layer
          id="slider-4-slide-4-layer-12"
          data-type="text"
          data-color="#8e989f"
          data-rsp_ch="on"
          data-xy="x:l,l,c,c;xo:140px,140px,8px,8px;y:m;yo:79px,79px,-108px,-48px;"
          data-text="w:normal;s:16,16,18,14;l:28,28,28,26;a:left,left,center,center;"
          data-frame_0="y:100%;"
          data-frame_0_mask="u:t;"
          data-frame_1="st:950;sp:1200;sR:950;"
          data-frame_1_mask="u:t;"
          data-frame_999="o:0;st:w;sR:6850;"
          style="z-index: 14; font-family: var(--body-font-family)"
          >
          This app is developed for the Resident,<br />
          Landlord, Developer and Tenant living in society.<br />
          User can manage vehicle information, visitor,<br />
          SOS alerts, vehicle in out notification.<br />
          User can also make voice call to reception.<br />
        </rs-layer>
        <!-- <rs-layer
          id="slider-4-slide-4-layer-13"
          class="rev-btn"
          data-type="button"
          data-rsp_ch="on"
          data-xy="x:l,l,c,c;xo:143px,143px,-5px,1px;y:t,t,m,m;yo:602px,562px,-25px,45px;"
          data-text="w:normal;s:16,16,16,14;l:40,40,26,26;"
          data-dim="minh:0px,0px,none,none;"
          data-padding="t:4,4,10,8;r:25;b:4,4,10,8;l:25;"
          data-border="bor:4px,4px,4px,4px;"
          data-frame_0="y:100%;"
          data-frame_0_mask="u:t;"
          data-frame_1="st:1320;sp:1200;sR:1320;"
          data-frame_1_mask="u:t;"
          data-frame_999="o:0;st:w;sR:6480;"
          data-frame_hover="bgc:linear-gradient(90deg, rgba(163,120,252,1) 0%, rgba(109,115,239,1) 100%);bor:4px,4px,4px,4px;bri:120%;"
          style="
            z-index: 15;
            background: linear-gradient(
              90deg,
              rgba(109, 115, 239, 1) 0%,
              rgba(163, 120, 252, 1) 100%
            );
            font-family: var(--body-font-family);
          "
          >Read More
        </rs-layer> -->
        <rs-layer
          id="slider-4-slide-4-layer-19"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:812px,812px,605px,373px;yo:128px,128px,324px,220px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:157px,157px,75px,46px;h:153px,153px,73px,45px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2770;sp:1000;sR:2770;"
          data-frame_999="o:0;st:w;sR:5230;"
          style="z-index: 10; font-family: Roboto"
        >
          <img
            src="./assets/sofbox-v2/revslider/assets/index-8/56.png"
            alt="images"
            width="157"
            height="153"
            data-no-retina
          />
        </rs-layer>
        <rs-layer
          id="slider-4-slide-4-layer-20"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:-425px,-425px,-175px,-101px;yo:123px,123px,109px,197px;"
          data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
          data-dim="w:476px,476px,230px,141px;h:381px,381px,184px,113px;"
          data-frame_0="y:100%;"
          data-frame_0_mask="u:t;"
          data-frame_1="st:2540;sp:1200;sR:2540;"
          data-frame_1_mask="u:t;"
          data-frame_999="o:0;st:w;sR:5260;"
          data-loop_0="y:-60px;"
          data-loop_999="y:60px;sp:10000;e:Sine.easeInOut;yym:t;"
          style="z-index: 9; font-family: Roboto"
        >
          <img
            src="./assets/sofbox-v2/revslider/assets/index-8/04-min.png"
            alt="images"
            width="790"
            height="633"
            data-no-retina
          />
        </rs-layer>
        <!--
                    -->
      </rs-slide>
    </rs-slides>
    <rs-progress
      class="rs-bottom"
      style="visibility: hidden !important"
    ></rs-progress>
  </rs-module>
  <script type="text/javascript">
    if (typeof revslider_showDoubleJqueryError === "undefined") {
      function revslider_showDoubleJqueryError(sliderID) {
        var err = "<div class='rs_error_message_box'>";
        err += "<div class='rs_error_message_oops'>Oops...</div>";
        err += "<div class='rs_error_message_content'>";
        err +=
          "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
        err +=
          "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' ->  'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
        err +=
          "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
        err += "</div>";
        err += "</div>";
        jQuery(sliderID).show().html(err);
      }
    }
  </script>
</rs-module-wrap>
