import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"],
})
export class ServicesComponent implements OnInit {
  titleSectionProp: any = {
    title: "Resident Feature",
    description: "Advanced settings that control all aspects of the app.",
  };

  List: any[] = [
    {
      title: "Multiple Unit",
      image: "./assets/sofbox-v2/images/home-8/home.png",
      desc: "Manage all different residences under Vajra App.",
      active: true,
    },
    {
      title: "Tenant",
      image: "./assets/sofbox-v2/images/home-8/tenant.png",
      desc: "Manage your tenant with Vajra App.",
    },
    {
      title: "My Family",
      image: "./assets/sofbox-v2/images/home-8/family.jpg",
      desc: "Manage you family members. One primary contact person.",
    },
    {
      title: "Complaint",
      image: "./assets/sofbox-v2/images/home-8/performance.png",
      desc: "Manage your all complaint regarding your unit.",
    },
    {
      title: "Vehicles",
      image: "./assets/sofbox-v2/images/home-8/vehicle.jpg",
      desc: "Easy registration of vehicle with security.",
    },
    {
      title: "Parking",
      image: "./assets/sofbox-v2/images/home-8/parking-lot.png",
      desc: "Hassle less parking facility",
    },
    {
      title: "E-Document",
      image: "./assets/sofbox-v2/images/home-8/google-docs.png",
      desc: "Easy upload of SPA and Tenancy Agreement.",
    },
    {
      title: "Family SOS Alarm",
      image: "./assets/sofbox-v2/images/home-8/sos_icon.png",
      desc: "Send an alert to family members for immediate assistance.",
    },
    {
      title: "Delivery",
      image: "./assets/sofbox-v2/images/home-8/cargo-truck.png",
      desc: "Receive your delivery without much effort.",
    },
    {
      title: "E-News",
      image: "./assets/sofbox-v2/images/home-8/newspaper.png",
      desc: "Receive broadcasts by admin.",
    },
    {
      title: "Facility Booking",
      image: "./assets/sofbox-v2/images/home-8/facility.png",
      desc: "Book facilities within the community.",
    },
    {
      title: "Instant Notification",
      image: "./assets/sofbox-v2/images/home-8/notification.png",
      desc: "Get instant update through notification visitor, vehicle in/out, etc",
    },
  ];

  // { title: 'Delivery', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-thumbs-up', desc: 'There are many variations of passages of Lorem Ipsum available.'},
  // { title: 'E-News', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-thumbs-up', desc: 'There are many variations of passages of Lorem Ipsum available.'},
  // { title: 'Facility Booking', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-thumbs-up', desc: 'There are many variations of passages of Lorem Ipsum available.'},
  // { title: 'Instant Notification', hoverClass: 'org-hover' ,icon: 'flaticon flaticon-thumbs-up', desc: 'There are many variations of passages of Lorem Ipsum available.'}
  constructor() {}

  ngOnInit(): void {}
}
