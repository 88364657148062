<div class="row" data-autoplay="true" data-loop="true" data-nav="true" data-dots="false" data-items="3" data-items-laptop="3" data-items-tab="2" data-items-mobile="2" data-items-mobile-sm="1" data-margin="30" >
    <div *ngFor="let list of List" class="item col-12 col-sm-6 col-md-4 mt-4">
        <div class="iq-masonry-item">
            <div class="iq-portfolio">
                <img class="img-fluid" src="{{ list.image }}" alt="img" style="height: 200px;">
                <div class="iq-portfolio-content text-left">
                    <div class="details-box clearfix">
                        <div class="consult-details">
                                <h5 class="text-hover">{{ list.title }}</h5>
                            <p class="mb-0">{{ list.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>