import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-trick',
  templateUrl: './tips-trick.component.html',
  styleUrls: ['./tips-trick.component.css']
})
export class TipsTrickComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left',
    title: 'Vajra-Patrol at a glance',
    descClass: "text-justify",
    description: 'Vajra-Patrol is a Real Time guard monitoring system which skyrockets the efficiency of security companies and offers them the ability to upgrade their services via technology. In brief, Vajra-Patrol incorporates all the basic features of a smart guard tour system solution:'
  };
  
  Services: any[] = [
    { title : 'Create and connect sites with checkpoints.' },
    { title : 'Receive SOS alerts and take immediate action.' },
    { title : 'Get e-mail notifications for missed checkpoints.' },
    { title : 'Send incidents reports to your clients or to the Monitoring Center.' },
    { title : 'Manage patrols remotely in real-time.' },
    { title : 'Verify guard tours online and check missed patrols.' },
  ];
  constructor() { }

  ngOnInit(): void {
  }
}
