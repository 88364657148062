import { Component, OnInit, ViewEncapsulation } from "@angular/core";
// @ts-ignore
import logoImg from "../../../assets/sofbox-v2/images/logo-color.png";
// @ts-ignore
import footerLogo from "../../../assets/sofbox-v2/images/home-2/logo-white.png";
import { PluginsService } from "../../sofbox/plugins.service";
import { ConstantsService } from "src/app/helpers/constants.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class IndexComponent implements OnInit {
  webPortalTabs: any[] = [
    {
      title: "User Management",
      description:
        "Vajra Patrol web application allows total control on different security entries' levels: administrators, managers, security companies, security guards and clients. A Vajra Patrol manager can control all the parameters regarding the levels down in the hierarchy. Vajra Patrol web application lets you add and edit all relevant information regarding the various users roles and allows the total remote management of any incident, guard, shcedule, client related to the security guard company or organization. Vajra Patrol web application lets you add and edit all relevant information regarding the various users roles and allows the total remote management of any incident, guard, shcedule, client related to the security guard company or organization.",
    },
    {
      title: "Advanced Reporting",
      description:
        "There are four basic reporting options, enabling export to PDF or excel format for further processing: Finished Scheduled Route Report, which informs about all accomplished or missed patrols within a specific time interval. Clients Events Report, including all events for a specific client with full details. Incidents Report, including all events filtered by clients' sites or guards. Guard Daily Tour Duration Report, informing about exact time and duration of accomplished guard tours..",
    },
    {
      title: "Manage and Assign Checkpoints",
      description:
        "The security account manager using Vajra-Patrol can easily assign checkpoints (QR-codes, NFC tags, Beacons) via the web interface of Vajra-Patrol. Each chekcpoint is assigned to a specific client site and location and so predefined tours can be scheduled for each security guard. Checkpoints assignment is the core of Vajra-Patrol guard tour monitoring software as it interconnects mobile and web application via checkpoints scan accomplished by the security guards.",
    },
  ];
  webPortalTitle: any = {
    class: "text-center",
    title: "Features",
    description:
      " Multiple features in one single app.",
  };

  mobileApplicationTabs1: any[] = [];
  mobileApplicationTabs2: any[] = [
    {
      title: "Start a Security Patrol Tour",
      description:
        "You can now start a new patrol by the simple press of a button. Guard tour accomplishment is now easier than ever via your smartphone. Just get on the place, launch Vajra-Patrol mobile app and Start a patrol tour. At the same moment, an alarm event is sent to the Vajra-Patrol web application via a cloud server, indicating that a new patrol has just launched.",
    },
    {
      title: "Add Multimedia",
      description: `You can not only send events but also communicate with the managing staff via multimedia interaction, including:
      Text messages
      Images captured at the moment of patrolling
      Audio messages
      All of the messages include signature validation to claim legal processes and extinguish ambiguity for guard's activities.`,
    },
    {
      title: "Security Guard Incident Report",
      description: `
      When a guard comes up with an urgent situation, he can send an immediate SOS alert to the monitoring center pressing the red SOS button. In addittion, he can choose between a list of predefined incidents to send, such as:
 A broken front door
 Missing keys
 Open Lights
 Broken windows`,
    },
    {
      title: "A Security Guard Checkpoint System",
      description: `It could never be more simple and real-time! Just pass from the buildings where checkpoints are placed (QRcode, Beacons or NFC tags), scan QR codes, pass the smartphone above NFC or scan Beacons from distance and a patrol event will be visible in the QR-Patrol web application in real-time. It simply means:
      Higher security level for both security guards and assets, as the managing staff is now aware of guard's position and can take immediate action in a case of alert
      No need for special security guard equipment (only a smartphone!)
      High reliability towards clients, as they can receive real-time email alerts when their buildings/assets are checked by security officers.`,
    },
  ];
  mobileApplicationHeader: any = {
    class: "text-left",
    title: "Mobile Application",
    description:
      "Add value to your security guard services Simplify guard tour inspections via cloud and smartphone technology",
  };

  footerText: string =
    "F-2-20,Sunsuria Forum No.1 Jalan Setia Dagang Al U13/Al Seksyen U13,40170 Shah Alam Selangor, Malaysia..";
  footerTitle: string = "Ready to get started?";
  footerDescription: string =
    "Manage your visitor & security on one platform..";
  public logoWhite: string = "./assets/images/vajra/logo_white.png";
  public navLogo: string = "./assets/images/vajra/icon.png";
  public footerLogo: string = "./assets/images/vajra/logo_white.png";

  public navItems: any = [
    {
      href: '#iq-home', title: 'Home',
      _is_active: true
    },
    { 
      href: '#', title: 'Solutions',
      is_link: false,
      _is_active: false,
      children: true,
      child: [
        { href: '/community-app', title: 'Vajra Community App' },
        { href: '/guard-patrol', title: 'Vajra Guard Patrol' },
        { href: '/visitor-panel', title: 'Vajra Visitor Panel' },
        { href: '/', title: 'Vajra Management Portal' },
        { href: '/access-control', title: 'Vajra Access Control' },
      ]
    },
    { href: '/contact-us', title: 'Contact Us', is_link: true  }
  ];

  titleSectionProp: any = {
    title: "Get tips & tricks on how to skyrocket your sales.",
    description:
      "Faff about only a quid blower I don’t want no agro bleeding chimney pot burke tosser cras nice one boot fanny.!",
  };

  List: any[] = [
    {
      name: "Suresh Rao",
      design: "CEO Sofbox",
      image: "./assets/sofbox-v2/images/home-2/ceo.jpg",
      description:
        "We Work For Your IDEAS And Believe One Day Your IDEAS Work For You....",
    },
  ];

  testimonialSectionProp: any = {
    class: "iq-rpt-zero",
    id: "iq-testimonial",
  };

  testimonialList1: any[] = [
    {
      name: "Alex xander",
      designation: "CEO Sofbox",
      description:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vero eos et consectetur accus adip adip.",
      image: "./assets/sofbox-v2/images/home-1/25.jpeg",
    },
    {
      name: "Julia Aann",
      designation: "COO Sofbox",
      description:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vero eos et consectetur accus adip adip.",
      image: "./assets/sofbox-v2/images/home-1/26.jpeg",
    },
    {
      name: "Mac Znder",
      designation: "Manager Sofbox",
      description:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vero eos et consectetur accus adip adip.",
      image: "./assets/sofbox-v2/images/home-1/27.jpeg",
    },
  ];
  constructor(private plugins: PluginsService, private constantsService: ConstantsService) {
    this.logoWhite = this.constantsService.logoWhite;
    this.navLogo = this.constantsService.navLogo;
    this.footerLogo = this.constantsService.footerLogo;
    this.footerText = this.constantsService.footerText;
    this.footerTitle = this.constantsService.footerTitle;
    this.footerDescription = this.constantsService.footerDescription;

    this.navItems = this.constantsService.navItems;
    this.List = this.constantsService.List;
    window.document.title = 'Vajra Visitor Panel'
  }

  ngOnInit() {
    // Init all plugins...
    const current = this;
    current.plugins.revolutionSlider();
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      current.plugins.index();
    }, 200);
  }
}
