<div id="loading">
    <div id="loading-center">
        <img src="./assets/sofbox-v2/images/loader-2.gif" alt="loader">
    </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox5 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content">
    <app-screenshot-slider></app-screenshot-slider>
    <app-services></app-services>
    <app-about></app-about>
    <app-features></app-features>
    <app-portfolio></app-portfolio>
    <app-pricing></app-pricing>
    <app-post></app-post>
</div>
<!-- End Main content -->
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle" [footerDescription]="footerDescription" [footerClass]="footerClass" [buttonClass]="buttonClass" >
    <ul menu>
        <li><a class="text-white" href="/about-us">About Us</a></li>
        <li><a class="text-white" href="/contact-us">Contact Us</a></li>
        <li><a class="text-white" href="/privacy-policy">Privacy Policy</a></li>
        <li><a class="text-white" href="/terms-user">Terms Of Use</a></li>
        <li><a class="text-white" href="/end-user">User License</a></li>
      </ul>
      <ul about_us>
        <li>
          <a class="text-white" href="/community-app">Vajra Community App</a>
        </li>
        <li>
          <a class="text-white" href="/guard-patrol">Vajra Guard Patrol</a>
        </li>
        <li>
          <a class="text-white" href="/management-portal">Vajra Visitor Management</a>
        </li>
        <li>
          <a class="text-white" href="/access-control">Vajra Access Control</a>
        </li>
        <!-- <li><a class="text-white" href="javascript:void(0)">News</a></li> -->
      </ul>
    <div testimonial>
        <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
    </div>
</app-v-two-footer-style2>