<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
      </div>
      <div class="col-lg-6">
        <div class="content-box">
          <h4>Visitor Check In Check out</h4>
          <p>
            A visitor check-in/out feature in a resident app could be a useful way to manage visitor access to a
            property and ensure the safety and security of residents. This feature could allow visitors to check in and
            out of the property through the app, and for property management to monitor visitor activity and maintain
            records for security purposes.
          </p>
        </div>
      </div>
      <div class="col-lg-6 d-lg-block d-none">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/sofbox-v2/images/home-7/user.png" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>

      <div class="col-lg-6 mt-5">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/images/visitorPanel/Walkin.png" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>
      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>Walkin</h4>
          <p>
            A walking feature in a visitor app could be a useful way for visitors to navigate the property and find
            their way around. This feature could provide visitors with a map of the property, along with directions to
            specific locations, such as a resident's apartment, property amenities, or nearby points of interest.
          </p>
        </div>
      </div>

      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>SOS Alerts</h4>
          <p>
            SOS feature in a resident app could be a helpful safety feature for residents in emergency situations. An
            SOS button in the app could be designed to quickly notify property management or emergency services in case
            of an urgent situation, such as a medical emergency, fire, or security threat.
          </p>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/images/visitorPanel/SOSAlert.jpg" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>

      <div class="col-lg-6 d-lg-block d-none">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/sofbox-v2/images/home-7/checkpoint.png" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>
      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>Visitor List</h4>
          <p>
            A visitor list feature in a visitor app could be a useful way for residents to keep track of their guests
            and visitor activity. This feature could provide residents with a list of their approved visitors and the
            dates and times of their visits, along with the ability to manage and update their visitor list as needed.
          </p>
        </div>
      </div>

      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>Instant Notification</h4>
          <p>
            An instant notification feature in a visitor app could be a useful way to keep visitors informed about
            important updates and events in real time. This feature could provide timely and relevant information to
            visitors, such as changes in property access or emergency alerts.
          </p>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/images/visitorPanel/InstantNotification.jpg" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>

      <div class="col-lg-6 d-lg-block d-none">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/images/visitorPanel/Delivery.jpg" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>
      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>Delivery</h4>
          <p>
            A delivery feature in the app could be a convenient and efficient way for residents to manage package
            deliveries. This feature could provide residents with real-time information about the status of their
            deliveries, and allow them to securely receive and track their packages from within the app.
          </p>
        </div>
      </div>

      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>Expected Visitor</h4>
          <p>
            An expected visitor feature in a visitor app could be a useful way for residents to notify property
            management and security staff about their expected visitors. This feature could allow residents to provide
            details about their visitors using their name, contact information, and expected arrival time, which can
            help property management and security staff verify their identity and ensure a smooth check-in process.
          </p>
        </div>
      </div>
      <div class="col-lg-6 d-lg-block d-none">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/images/visitorPanel/ExpectedVisitor.jpg" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="popup-images align-right">
          <div class="img-one wow zoomIn" data-wow-duration="0.6s">
            <img src="./assets/images/visitorPanel/OCRReader.jpg" alt="image1" />
          </div>
          <div class="d-block"></div>
        </div>
      </div>
      <div class="col-lg-6 mt-5">
        <div class="content-box">
          <h4>OCR Reader</h4>
          <p>
            OCR stands for Optical Character Recognition. It is a technology that is used to convert scanned images, PDF
            files, and other digital documents into editable and searchable formats. OCR technology analyzes the image
            and identifies the characters in the image, converting them into machine-readable text.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>