import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-tricks4',
  templateUrl: './tips-tricks4.component.html',
  styleUrls: ['./tips-tricks4.component.css']
})
export class TipsTricks4Component implements OnInit {
  titleSectionProp: any = {
    class: 'text-left',
    title: 'Unique reporting capabilities',
    descClass: "text-justify",
    description: 'Vajra-Patrol is a smart guard tour and patrol monitoring system aiming to improve daily operations in security companies and arm clients with total control over their territories. Multiple reporting options are available:'
  };
  
  Services: any[] = [
    { title : 'Finished Scheduled Routes (Scanned or Missed Check Points – filtering by date or missed checkpoints routes).' },
    { title : 'Advanced filtering by client in all patrol events (including Incidents, MME, scans) and PDF/Excel export capabilities.' },
    { title : 'Total patrol duration report filtered by guard and patrol.' },
    { title : 'Incidents reports filtered by Client, site and guard with the option to send them via e-mail to Client.' },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
