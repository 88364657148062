
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature2',
  templateUrl: './feature2.component.html',
  styleUrls: ['./feature2.component.css']
})
export class Feature2Component implements OnInit {

  titleSectionProp: any = {
    class: 'mb-0',
    title: 'Popular features in Vajra Resident Managment',
    description: ': Resident Management is a property management system that offers various features to streamline resident management processes.',
    descClass: 'mb-0'
  };
  

  List: any[] = [
    { title: 'Resident Managemnt', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/family.jpg', style:'height:60px;width:60px;left: 0px;', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Tenant Managemnt', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/tenant.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Resident SOS', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-1/sos_icon.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Vehicle Managemnt', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/vehicle.jpg', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
     { title: 'E-News', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/newspaper.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Facility Booking', hoverClass: 'green-hover' ,icon: './assets/sofbox-v2/images/home-1/facility.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Delivery', hoverClass: 'blue-hover' ,icon: './assets/sofbox-v2/images/home-1/cargo-truck.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'},
    { title: 'Complaint', hoverClass: 'org-hover' ,icon: './assets/sofbox-v2/images/home-1/performance.png', style:'height:60px;width:60px', desc: 'There are many variations of passages of Lorem Ipsum available.'}
  ]
  constructor() { }

  ngOnInit() {
  }

}
