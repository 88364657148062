        <section>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                            <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                    </div>
                    <div class="col-lg-6">
                        
                    </div>
                    <div class="col-lg-6">
                        <app-tabs1 [tabs]="tabs2"></app-tabs1>
                    </div>
                </div>
            </div>
        </section>
