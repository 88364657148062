import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-frequentlyasked",
  templateUrl: "./frequentlyasked.component.html",
  styleUrls: ["./frequentlyasked.component.css"],
})
export class FrequentlyaskedComponent implements OnInit {
  titleSectionProp: any = {
    class: "text-center",
    title: "Frequently Asked Questions",
    description: `Always question the "why"; Don't be satisfied with only knowing the "how"`,
  };
  tabs: any[] = [
    {
      title: "Which industry needs a guard tour system?",
      description:
        "He implementation of a guard tour system will help companies to monitor their officers accurately in time and manage their assets more effectively, upgrading their security services. So, many different industries can take advantage of the use of a guard tour system.",
    },
    {
      title: "How to eliminate paper load?",
      description:
        "It is impossible to keep thorough recordings of any work process without using computer based system which automates procedures and eliminates paperwork. Even if there are strict guidelines in the delivery and conduct of reports and other necessary documents, there is no a reliable way to track and manage the whole procedure without losing information or valuable time..",
    },
    {
      title: "How a security guard tour system helps to handle the situation?",
      description:
        "Using a modern guard tour system (especially a cloud based one), officers daily life is much easier. They only use a mobile application and via their smartphone and some simple touches on the screen, they can scan checkpoints, send immediate alerts and SOS messages, send images and sound recordings and add their signatures to their real-time reports.",
    },
    {
      title: "Which method will be used to track the problem and safety issue?",
      description:
        "I there is not a comprehensive automated system to structure a plan and specific schedules for each guard. Guard tour systems offer a consistent solution integrating technology and allowing people who manage guard tours to form a complete schedule program for their company.",
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
