import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
   }

  reqForDemo(obj){
    return this.http.post(environment.apiUrl + 'req-for-demo', obj);
  }

  contactUs(obj){
    return this.http.post(environment.apiUrl + 'contact-us', obj);
  }

  newsLetterSubs(email){
    return this.http.post(environment.apiUrl + 'subscribe', email);
  }
}
