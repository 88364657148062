import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// @ts-ignore
import logoImg from '../../../assets/sofbox-v2/images/logowhite.png';
// @ts-ignore
import footerLogo from '../../../assets/sofbox-v2/images/home-2/logo-white.png';
import { PluginsService } from '../../sofbox/plugins.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit {
  vgpFeatures: any[] = [
    {
      image: "",
      text: "Manual checkin/out"
    },
    {
      image: "",
      text: "Patrol"
    },
    {
      image: "",
      text: "Checkpoints"
    },
    {
      image: "",
      text: "Visitor checkin/out"
    },
    {
      image: "",
      text: "Switch Guard"
    },
    {
      image: "",
      text: "Guard Attendance"
    },
    {
      image: "",
      text: "SOS"
    },
    {
      image: "",
      text: "Report Incident"
    },
    {
      image: "",
      text: "Shift Detail"
    },
    {
      image: "",
      text: "Shift Detail"
    },
    {
      image: "",
      text: "Start/end Shift"
    },
    {
      image: "",
      text: "Self checkin/out"
    },
  ]

  titleSectionProp: any = {
    title: 'Vajra Solutions',
    description: 'Your Lifestyle, Powered by Our Life’s Work'
  };
  titleSectionProp2: any = {
    title: 'Our Products',
    description: 'If you are planning on developing a product landing'
  };

  guardPatrolTop: any = {
    title: 'Guard Patrol',
    description: 'Get all details in real-time, track guards and patrol records anytime, anywhere.'
  }

  adminPanelTop: any = {
    title: 'Management Portal',
    description: 'Live Tracking Activity with the command center with all resident and visitor databases.'
  }

  anprPanelTop: any = {
    title: 'Access Control Management',
    description: "Reduce congestion in the community's entry/exit"
  }

  vvpSectionTitleProp:any = {
    title: 'Visitor Panel',
    description: 'If you are planning on developing a product landing'
  }

  List2: any[] = [
    {
      title: 'Management Portal',
      image: './assets/sofbox-v2/images/home-9/90.png',
      desc: 'Live Tracking Activity'
    },
    {
      title: 'Community App',
      image: './assets/sofbox-v2/images/home-9/91.png',
      desc: 'Smart Residential Community'
    },
    {
      title: 'Guard Patrol',
      image: './assets/sofbox-v2/images/home-9/92.png',
      desc: 'Keep the Guards Alert for A Safer Community'
    },
    {
      title: 'Visitor Management',
      image: './assets/sofbox-v2/images/home-9/93.png',
      desc: 'Manage your visitor & security on one platform'
    },
    {
      title: 'Access Control Management',
      image: './assets/sofbox-v2/images/home-9/94.png',
      desc: 'High Security at Your Convenience'
    },
    {
      title: 'Kiosk',
      image: './assets/sofbox-v2/images/home-9/95.png',
      desc: 'Manage Visiting Activity'
    }
  ]

  adminPanelList:any = []

  anprDetails:any = [
    {
      image: "",
      text:"Auto open barrier gate"
    },
    {
      image: "",
      text:"Vehicle Inout Transaction"
    },
    {
      image: "",
      text:"AI ANPR with Display"
    },
    {
      image: "",
      text:"Vehicle Colour Detection"
    },
    {
      image: "",
      text:"Vehicle Size Detection"
    },
    {
      image: "",
      text:"Traffic Direction Detection"
    },
  ]
  
  vcaTitle:any = {
    class: 'text-center',
    title: 'Community App',
    description: 'Manage your property and security with one click'
  };

  vvpTitle:any = {
    class: 'text-center',
    title: 'Visitor Panel',
    description: 'Get all details of your visiting person, Manage single or multiple checks In/out with an instant notification.'
  };


  vcaList:any[] = [
    { 
      title: 'Resident Management',
      id: 'tab-unique-design',
      image: './assets/images/home/vca/occupancy.png',
      description: 'Resident management refers to the process of effectively managing the residents of a particular property or community.',
      active: true
    },
    { 
      title: 'Unit Management',
      id: 'tab-easy-to-customize',
      image: './assets/images/home/vca/unit.png',
      description: 'The goal of unit management is to ensure that each unit is properly maintained, occupied, and functioning efficiently.'
    },
    { 
      title: 'Visitor Management',
      id: 'tab-multipurpose-layout',
      image: './assets/images/home/vca/visitor.png',
      description: 'Visitor management is to ensure the safety and security of the property and its occupants while providing a welcoming and efficient experience for visitors.'
    },
    { 
      title: 'Booking Facility',
      id: 'tab-multipurpose-layout',
      image: './assets/images/home/vca/booking.png',
      description: 'This involves accepting reservations from individuals or organizations, collecting information about the event or activity, and confirming the reservation.'
    },
    { 
      title: 'SOS',
      id: 'tab-multipurpose-layout',
      image: './assets/images/home/vca/sos.png',
      description: 'SOS feature in a resident app could be a helpful safety feature for residents in emergency situations. An SOS button in the app could be designed to quickly notify property management or emergency services in case of an urgent situation, such as a medical emergency, fire, or security threat.'
    },
    { 
      title: 'Complaint',
      id: 'tab-multipurpose-layout',
      image: './assets/images/home/vca/incident.png',
      description: 'The feature that allows residents to report issues or concerns about their living environment or the services provided by the property management. This section could be designed to make it easy for residents to submit their complaints and for property management to track and respond to them in a timely manner.'
    },
    { 
      title: 'E News',
      id: 'tab-multipurpose-layout',
      image: './assets/images/home/vca/unit.png',
      description: 'An E-news section in a resident app could be a feature that provides residents with regular updates and news about the property, community events, and other relevant information. This section could be designed to keep residents informed and engaged with their living environment and to foster a sense of community among residents.'
    },
    { 
      title: 'Vehicle Soft/Unlock',
      id: 'tab-multipurpose-layout',
      image: './assets/images/home/vca/vehicle.png',
      description: 'A vehicle soft-lock feature in a resident app could be a convenient and secure way for residents to control access to their vehicles in a parking area. This feature could allow residents to remotely lock and unlock their vehicle, and to control who has access to it, all from within the app.'
    },
  ];

  vvpList:any[] = [
    { 
      title: 'Walk-in',
      id: 'tab-unique-design',
      image: './assets/sofbox-v2/images/home-9/96.png',
      description: 'Walk-in refers to the process of someone physically coming into a particular establishment or facility without a prior appointment or reservation.',
      active: true
    },
    { 
      title: 'Visitor Check-in/check-out',
      id: 'tab-easy-to-customize',
      image: './assets/sofbox-v2/images/home-6/615.png',
      description: 'Visitor check-in/out refers to the process of managing the entry and exit of visitors to a particular establishment or property.'
    },
    { 
      title: 'OCR',
      id: 'tab-multipurpose-layout',
      image: './assets/sofbox-v2/images/home-6/612.jpg',
      description: 'OCR technology uses software that analyzes the characters in an image or document and translates them into machine-readable text.'
    },
    { 
      title: 'Instant Notification',
      id: 'tab-multipurpose-layout',
      image: './assets/sofbox-v2/images/home-6/612.jpg',
      description: 'An instant notification feature could be a useful way to keep residents informed about important updates and events in real time. This feature could provide timely and relevant information to residents, such as emergency alerts, building maintenance notifications, package deliveries, and other time-sensitive information.'
    },
    { 
      title: 'SOS Alarm',
      id: 'tab-multipurpose-layout',
      image: './assets/sofbox-v2/images/home-6/612.jpg',
      description: 'SOS feature in a resident app could be a helpful safety feature for residents in emergency situations. An SOS button in the app could be designed to quickly notify property management or emergency services in case of an urgent situation, such as a medical emergency, fire, or security threat.'
    },
    { 
      title: 'Delivery',
      id: 'tab-multipurpose-layout',
      image: './assets/sofbox-v2/images/home-6/612.jpg',
      description: 'A delivery feature in app could be a convenient and efficient way for residents to manage package deliveries. This feature could provide residents with real-time information about the status of their deliveries, and allow them to securely receive and track their packages from within the app.'
    },
    { 
      title: 'E Pass',
      id: 'tab-multipurpose-layout',
      image: './assets/sofbox-v2/images/home-6/612.jpg',
      description: 'An E-pass feature could be a useful way to manage visitor access to a property. This feature could allow residents to generate an electronic pass or invitation for their visitors, which can be presented at the security gate or reception desk for entry into the property.'
    },
    { 
      title: 'Kiosk',
      id: 'tab-multipurpose-layout',
      image: './assets/sofbox-v2/images/home-6/612.jpg',
      description: 'A kiosk feature in the app could be a self-service option for residents to access information or perform certain tasks related to their living environment or property management. This feature could provide residents with a convenient and efficient way to manage their needs without having to contact property management staff directly.'
    },
  ];
  
  public navLogo: string = './assets/images/vajra/logo_white.png';
  public logoWhite: string = './assets/images/vajra/logo_white.png';
  public footerLogo: string = './assets/images/vajra/logo_white.png';
  footerText: string = 'F-2-20,Sunsuria Forum No.1 Jalan Setia Dagang Al U13/Al Seksyen U13,40170 Shah Alam Selangor, Malaysia.';
  footerTitle: string = 'Ready to get started?';
  footerDescription: string = 'Manage your visitor & security on one platform.';
  public navItems: any = [
    {
      href: '/', title: 'Home',
      _is_active: true,
      is_link: true
    },
    { 
      href: '#', title: 'Solutions',
      is_link: false,
      _is_active: false,
      children: true,
      child: [
        { href: '/community-app', title: 'Vajra Community App' },
        { href: '/guard-patrol', title: 'Vajra Guard Patrol' },
        { href: '/visitor-panel', title: 'Vajra Visitor Panel' },
        { href: '/management-portal', title: 'Vajra Management Portal' },
        { href: '/access-control', title: 'Vajra Access Control' },
      ]
    },
    { href: '/contact-us', title: 'Contact Us', is_link: true  }
  ];

  List: any[] = [
    { name: 'Suresh Rao', design: 'CEO', image: './assets/sofbox-v2/images/home-2/ceo.jpg', description: 'We Work For Your IDEAS And Believe One Day Your IDEAS Work For You....'},
  ];
  constructor(private plugins: PluginsService) { 

  }

  ngOnInit() {
    // Init all plugins...
    const current = this;
    current.plugins.revolutionSlider();
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function () {
      current.plugins.index();
    }, 200);
  }

}
