import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-work',
  templateUrl: './how-work.component.html',
  styleUrls: ['./how-work.component.css']
})
export class HowWorkComponent implements OnInit {

  titleSectionProp = {
    title: 'Safety Features',
    description: 'Some safety features for community.',
    class: 'text-left'
  };

  List : any[] = [
    { title: 'SOS', icon: './assets/sofbox-v2/images/home-8/sos_icon.png', hoverClass: 'purple-hover',style:'height:75px;width:70px;left: 0px; padding-bottom:5px'},
    { title: 'Complaint', icon: './assets/sofbox-v2/images/home-8/performance.png', hoverClass: 'org-hover', style:'height:70px;width:60px;left: 0px; padding-bottom:5px' },
    { title: 'Soft Lock/Unlock', icon: './assets/sofbox-v2/images/home-8/locked.png', hoverClass: 'green-hover' , style:'height:65px;width:60px;left: 0px;padding-bottom:5px'},
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
